import {
  Avatar, Badge, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, ListItem, ListItemAvatar, ListItemText, Stack, TextField, Tooltip,
  Typography
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { auto } from '@popperjs/core';
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import { AllUserListByRoleRoute, DeleteUserRoute, SendQRCodeToAssistantRoute, UserSessionStatusRoute, getSubscriptionDataUsage } from "../../Common/api.routes";
import {
  ActiveStatus,
  ActiveStatusID,
  ButtonActionAdd,
  ButtonActionCallSession,
  ButtonActionDelete,
  ButtonActionEdit,
  ButtonActionInitiateCall,
  ButtonActionSendQRCode,
  ButtonReactivate,
  CALL_TO_ASSISTANT,
  DefaultAssistant,
  DefaultGridRowSize,
  FormAddMode,
  FormUpdateMode,
  FormViewMode,
  GridRowsPerPageOptions,
  InActiveStatus,
  InActiveStatusID,
  countries, dummyCallSessionID,
  navigateToCallSessions,
  userCallStatusBusy, userCallStatusOffline,
  userCallStatusOnline,
} from "../../Common/helper";
import { GetGridNoRecordMessage, GetNonDeletedUser, GetNonLoggedInUser, GetShortDateWithLuxon, GetTimeWithAMPMWithLuxon, checkUserCreationCount, filterOnlineUsers, formatPhoneNumber, isAuthenticated, searchFilterFunction, stringAvatar, subscriptionDataUsage } from "../../Common/helperFunction";
import { GridStyling } from "../../Common/sharedStyling";
import CadisButton from "../../Components/Input/CadisButton";
import BuildGridAction from "../../Components/Input/cadisBuildGridAction";
import apis from "../../HttpConfig/Api";
import { ROLE, ROLENAMES } from "../../Interfaces/IConstants";
import { IUser } from "../../Interfaces/IUser";
import { IAssistantsData } from "../../Interfaces/lAssistantsData";
import { useSocketContext } from "../../context/SocketContext";
import { useUserRoleContext } from "../../context/UserRoleContext";
import DataLimitPopup from "../CallScreen/DataLimitPopup";
import AddUpdateOrganizationAdmin from "../OrganizationAdmin/AddUpdateOrganizationAdmin";

const AssistantList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const user = isAuthenticated();
  const userRole = ROLENAMES.Assistant;
  const { userLoginRole, handleToggleLoader } = useUserRoleContext();
  const [localRole, setLocalRole] = useState(userLoginRole);
  const [searchQuery, setSearchQuery] = useState("");
  const [showAllUserData, setShowAllUserData] = useState([]);
  const [showNonDeletedUserData, setShowNonDeletedUserData] = useState([]);
  const [openReactivateUserDialog, setOpenReactivateUserDialog] = useState({
    open: false,
    UserID: null,
    UserName: "",
  });
  const [dataFinished, setDataFinished] = useState(false);
  const [asstDataFinished, setAsstDataFinished] = useState(false);

  let userStatus = useRef<any>([]);

  useEffect(() => {
    const sessionStorageUserRole: any = Number(sessionStorage.getItem("role"));
    if (sessionStorageUserRole) {
      setLocalRole(sessionStorageUserRole);
    } else {
      setLocalRole(userLoginRole);
    }
  }, [userLoginRole]);

  // for filtering the DataGrid table by search
  const handleSearchInputChange = (event: any) => {
    let value = event.target.value;
    setSearchQuery(value);

    if (event.target.value !== "") {
      let filterData: any = searchFilterFunction(data, value);
      setAssistantData(filterData);
    } else if (event.target.value === "") {
      setAssistantData(data);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "user_id",
      headerName: "ID",
      width: 0,
      hide: true,
      cellClassName: "app-theme-cell",
      headerClassName: "app-theme-header",
      filterable: false,
      sortable: false,
    },
    {
      field: "full_name",
      headerName: "Name (Individual/ Team)",
      cellClassName: "app-theme-cell",
      headerClassName: "app-theme-header",
      minWidth: 230,
      flex: 1,
      renderCell: ({ row }: any) => {
        return (
          <>
            <ListItem disablePadding>
              <ListItemAvatar>
                <Badge
                  overlap="circular"
                  title={
                    userStatus.current?.filter(
                      (user: any) => user.username === row.user_name
                    )[0]?.username === row.user_name
                      ? userStatus.current?.filter(
                        (user: any) => user.username === row.user_name
                      )[0]?.status
                      : "offline"
                  }
                  badgeContent={localRole === ROLE.Consultant ? "" : 0}
                  color={
                    userStatus.current?.filter(
                      (user: any) => user.username === row.user_name
                    )[0]?.status !== "online"
                      ? userStatus.current?.filter(
                        (user: any) => user.username === row.user_name
                      )[0]?.status !== "busy"
                        ? "secondary"
                        : "error"
                      : "success"
                  }
                >
                  <Avatar
                    {...stringAvatar(row.full_name)}
                    src={row.user_profile_img}
                  />
                </Badge>
              </ListItemAvatar>
              <Tooltip title={`${row.full_name ?? ""}`}>
                <ListItemText
                  sx={{ textTransform: "capitalize" }} className="long-col-name-ellipsis"
                  primary={`${row.full_name ?? ""}`}
                  secondary=""
                />
              </Tooltip>
            </ListItem>
          </>
        );
      },
      filterable: false,
    },
    {
      field: "user_email",
      headerName: "Email",
      flex: 1,
      minWidth: 300,
      cellClassName: "app-theme-cell",
      headerClassName: "app-theme-header",
      filterable: false,
      sortable: false,
      renderCell: ({ row }: any) => (
        <Tooltip title={row.user_email}>
          <span className="table-cell-trucate">{row.user_email}</span>
        </Tooltip>
      ),
    },
    {
      field: "phone_number_m",
      headerName: "Phone",
      minWidth: 130,
      flex: 1,
      cellClassName: "app-theme-cell",
      headerClassName: "app-theme-header",
      filterable: false,
      sortable: false,
      renderCell: ({ row }: any) => {
        const phoneNumber = row.phone_number_m;
        const phoneNumberTwo = row.phone_number_nm;
        return (
          <Stack direction="column" textAlign={"left"}>
            <Box>{formatPhoneNumber(phoneNumber)}</Box>
            <Box>{formatPhoneNumber(phoneNumberTwo)}</Box>
          </Stack>
        );
      },
    },
    {
      field: "lastCallTime",
      headerName: "Last Call",
      minWidth: 140,
      flex: 1,
      cellClassName: "app-theme-cell",
      headerClassName: "app-theme-header",
      filterable: false,
      renderCell: ({ row }: any) => {
        const { call } = row;
        let isLastCallMadeIsDummy = false;
        if (call) {
          isLastCallMadeIsDummy =
            call.call_consultant_id === dummyCallSessionID;
        }
        return isLastCallMadeIsDummy ? null : (
          <ListItem disablePadding>
            <ListItemText
              sx={{ textTransform: "capitalize" }}
              primary={
                call ? `${GetShortDateWithLuxon(call.date) ?? null}` : null
              }
              secondary={
                call
                  ? `${GetTimeWithAMPMWithLuxon(call.lastCallStartTime)} (${call.duration ?? null
                  })`
                  : null
              }
            />
          </ListItem>
        );
      },
    },
    {
      field: "user_status",
      headerName: "Status",
      minWidth: localRole === ROLE.Consultant ? 0 : 100,
      flex: 1,
      cellClassName: localRole === ROLE.Consultant ? 'app-theme-cell pl-0 pr-0' : 'app-theme-cell',
      headerClassName: localRole === ROLE.Consultant ? 'app-theme-header pl-0 pr-0' : 'app-theme-header',
      hide: localRole === ROLE.Consultant,
      filterable: false,
      renderCell: ({ row }: any) => {
        return (
          <>
            <Chip
              size="small"
              label={
                row.user_status === ActiveStatusID
                  ? ActiveStatus
                  : InActiveStatus
              }
              color={row.user_status === ActiveStatusID ? "success" : "error"}
            />
          </>
        );
      },
    },
    {
      field: "actions1", headerAlign: 'left', headerName: "", sortable: false,
      cellClassName: localRole === ROLE.Consultant ? 'app-theme-cell' : ' app-theme-cell pl-0 pr-0', headerClassName: localRole === ROLE.Consultant ? 'app-theme-header' : ' app-theme-header pl-0 pr-0',
      minWidth: localRole === ROLE.Consultant ? 120 : 1, width: localRole === ROLE.Consultant ? 120 : 1, align: 'left', filterable: false,
      renderCell: (params) => {
        const userCallStatus = GetUserCallStatus(params?.row);
        return (
          <Stack direction="row" spacing={0}>
            {localRole === ROLE.Consultant ? (
              <CadisButton id=''
                onClick={() => OnActionClick(ButtonActionInitiateCall, params?.row)}
                color={
                  userCallStatus == userCallStatusOffline ? "inherit"
                    : userCallStatus == userCallStatusBusy ? "error"
                      : "secondary"
                }
                variant="contained"
                size="small" disabled={userCallStatus == userCallStatusOffline} >
                {
                  userCallStatus == userCallStatusOffline ? t("lbl_offLine")
                    : userCallStatus == userCallStatusBusy ? t("lbl_busy")
                      : t("lbl_connect")
                }
              </CadisButton>
            ) : null
            }
          </Stack >
        );
      },
    },
    {
      field: "actions",
      headerAlign: "center",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      cellClassName: "app-theme-cell bg-action",
      headerClassName: "app-theme-header bg-action",
      minWidth: localRole === ROLE.Consultant ? 80 : 228,
      align: "center",
      filterable: false,
      renderCell: (params) => {
        const isCallDetailsAvailable = params.row.call;
        let didLoggedinConsultantCalledThisAssistant = true;
        if (localRole === ROLE.Consultant) {
          didLoggedinConsultantCalledThisAssistant = params.row.hasOwnProperty('callLog');
        }
        else if (localRole === ROLE.OrgAdmin) {
          didLoggedinConsultantCalledThisAssistant = isCallDetailsAvailable.call_consultant_id !== dummyCallSessionID;
        }
        return (
          <Stack direction="row" spacing={0}>
            <BuildGridAction
              title={`${didLoggedinConsultantCalledThisAssistant ? t('lbl_viewCallSessions') : t("lbl_noCallSessions")}`}
              onClickAction={(e: any) => {
                if (didLoggedinConsultantCalledThisAssistant) { onClick(e, params) }
              }
              }
              actionParams={params}
              placement="left" color={`${didLoggedinConsultantCalledThisAssistant ? 'primary' : 'default'}`}
              ActionId={ButtonActionCallSession} >
              {<span className='cad-call-circle'></span>}
            </BuildGridAction>

            {localRole === ROLE.OrgAdmin ? (
              <>
                <BuildGridAction
                  title={t("lbl_resendNewQRCode")}
                  onClickAction={(e: any) => onClick(e, params)}
                  actionParams={params}
                  placement="bottom-end"
                  color="primary"
                  ActionId={ButtonActionSendQRCode}
                >
                  {<span className="cad-barcode"></span>}
                </BuildGridAction>

                {/* left empty box for adding space between edit and other left actions */}
                <Box className='grid-act-btn'></Box>

                <BuildGridAction
                  title={`${t('lbl_edit')} ${t("lbl_assistant")}`}
                  onClickAction={(e: any) => onClick(e, params)}
                  actionParams={params}
                  placement="bottom-end"
                  color="primary"
                  ActionId={ButtonActionEdit}
                >
                  {<span className="cad-edit"></span>}
                </BuildGridAction>

                {params.row.user_status === InActiveStatusID ? (
                  <BuildGridAction
                    title={t('lbl_hideAssistant')}
                    onClickAction={(e: any) => onClick(e, params)}
                    actionParams={params}
                    placement="bottom-end"
                    color="error"
                    ActionId={ButtonActionDelete}
                  >
                    {<span className="cad-archive"></span>}
                  </BuildGridAction>
                ) : (
                  <Box className="grid-act-btn"></Box>
                )}
              </>
            ) : null}

          </Stack>
        );
      },
    },
  ];

  const GetUserCallStatus = ({ user_name }: any) => {
    const currentUserAvailabilityStatus =
      userStatus.current?.filter(
        (user: any) => user.username === user_name
      )[0]?.status !== userCallStatusOnline
        ? userStatus.current?.filter(
          (user: any) => user.username === user_name
        )[0]?.status !== userCallStatusBusy
          ? "secondary"
          : "error"
        : "success";

    return currentUserAvailabilityStatus.toLowerCase() === "secondary" ? userCallStatusOffline :
      currentUserAvailabilityStatus.toLowerCase() === "error" ? userCallStatusBusy : userCallStatusOnline
  }

  // getting socket from context
  const {
    onlineUsers,
    callerToneRef,
    socketRef,
    openIncomingCallBox,
    setUserDetails,
    setCallToAssistant,
    openCallToAssistantBox,
    openReconnectDialog,
  } = useSocketContext();
  // Assistant list state
  const [assistantData, setAssistantData] = useState<IAssistantsData[]>([]);
  const [data, setData] = useState([]);
  const [isOpenAddUpdateAssistant, setIsOpenAddUpdateAssistant] = useState<boolean>(false);
  const [assistantCreationMode, setAssistantCreationMode] = useState(FormViewMode);
  const [currentAssistantData, setCurrentAssistantData] = useState<IUser>(DefaultAssistant);
  const [pageSize, setPageSize] = useState<number>(DefaultGridRowSize);
  const [assistantListFlow, setAssistantListFlow] = useState("assistantFlow");
  const [consultantSpecialities, setconsultantSpecialities] = useState([
    { speciality_id: 0, speciality: "" },
  ]);
  const [openDeleteAssistantDialog, setOpenDeleteAssistantDialog] = useState({
    open: false,
    UserID: null,
    AssistantName: "",
  });

  const GetAssistantsList = async () => {
    handleToggleLoader(true);
    const loggedInUserRole: number = Number(sessionStorage.getItem("role"));
    const params = {
      role: ROLE.Assistant,
      orgID: user.orgId,
      loggedInUserRole: loggedInUserRole
    };
    await apis.instance
      .get(`${AllUserListByRoleRoute}`, { params })
      .then((resp: any) => {
        if (resp.data?.success) {
          let response = resp?.data?.data.map((item: any) => {
            return {
              ...item,
              full_name: `${item.user_fname} ${item.user_lname ?? ""}`,
            };
          });
          allAssistantData(response);
          const nonDeletedAssistants = GetNonDeletedUser(response, user.userId);
          settingData(nonDeletedAssistants);
        }
        handleToggleLoader(false);
      })
      .catch((err) => {
        handleToggleLoader(false);
        if (err?.response.status !== 401) toast.error(err.response?.data?.message)
        console.log(err);
      });
  };

  const allAssistantData = (response: string[]) => {
    const allUsers = GetNonLoggedInUser(response, user.userId);
    const sortedAllDataByFullnameThenByStatus =
      SortDataByIntialThenBySecondaryKeys(
        allUsers,
        "userFullName",
        "user_status"
      );
    setShowAllUserData(sortedAllDataByFullnameThenByStatus);
  };

  const settingData = (data: any) => {
    const sortedDataByNameThenByStatus = SortDataByIntialThenBySecondaryKeys(
      data,
      "user_fname",
      "user_status"
    );
    setAssistantData(sortedDataByNameThenByStatus);
    setData(sortedDataByNameThenByStatus);
    setShowNonDeletedUserData(sortedDataByNameThenByStatus);
  };

  const SortDataByIntialThenBySecondaryKeys = (
    data: any[],
    initialSortKey: string,
    secondarySortKey: string
  ) => {
    const sortedData: any = data
      .sort(function (a, b) {
        if (a.user_fname < b.user_fname) {
          return -1;
        }
        if (a.user_fname > b.user_fname) {
          return 1;
        }
        return 0;
      })
      .sort(function (x, y) {
        if (x.user_status > y.user_status) {
          return -1;
        }
        if (x.user_status < y.user_status) {
          return 1;
        }
        return 0;
      })
      .sort(function (p, q) {
        if (p.user_is_deleted < q.user_is_deleted) {
          return -1;
        }
        if (p.user_is_deleted > q.user_is_deleted) {
          return 1;
        }
        return 0;
      });
    return sortedData;
  };

  const DeleteAssistant = async () => {
    const assistantData = {
      userID: openDeleteAssistantDialog.UserID,
    };
    try {
      await apis.instance
        .put(`${DeleteUserRoute}`, assistantData)
        .then((resp: any) => {
          if (resp.data.success) {
            handleCloseDeleteAssistant();
            GetAssistantsList();
            toast.success(t('responseMsg.msg_hideAssistant'));
          } else {
            toast.error(resp.data.error);
          }
        })
        .finally();
    } catch (error: any) {
      toast.error(t("responseMsg.msg_wrongEntry"));
      console.log(error.message);
    }
  };

  const handleCloseDeleteAssistant = () => {
    setOpenDeleteAssistantDialog({
      open: false,
      UserID: null,
      AssistantName: "",
    });
  };

  useEffect(() => {
    GetAssistantsList();

    const username: string | null = JSON.parse(
      sessionStorage.getItem("username")!
    );
    let userRole: number | null = parseInt(
      JSON.parse(sessionStorage.getItem("role")!)
    );
    setUserDetails({ username: username, userrole: userRole });

    if (!socketRef.current && userRole === 3 && apis.socketURL) {
      const token = sessionStorage.getItem('token');
      const socketAuth = {
        query: { token },
      };
      socketRef.current = io(apis.socketURL, socketAuth);
      socketRef.current.on('connect', () => {
        console.log('Socket connected');
      });
    }
    getPageTitleHeight();
  }, []);

  useEffect(() => {
    if (
      filterOnlineUsers(userStatus.current, onlineUsers)?.username !==
      onlineUsers?.username &&
      onlineUsers?.status === "online"
    ) {
      userStatus.current.push({
        username: onlineUsers?.username,
        status: onlineUsers?.status,
      });
    } else if (
      filterOnlineUsers(userStatus.current, onlineUsers)?.username ===
      onlineUsers?.username &&
      onlineUsers?.status === "offline"
    ) {
      userStatus.current = userStatus.current.filter(
        (item: any) => item.username !== onlineUsers.username
      );
    } else if (
      filterOnlineUsers(userStatus.current, onlineUsers)?.username ===
      onlineUsers?.username &&
      onlineUsers?.status === "busy"
    ) {
      const index = userStatus.current.findIndex(
        (user: any) => user.username === onlineUsers?.username
      );
      userStatus.current.map((user: any, i: number) => {
        if (i === index) {
          user.status = onlineUsers?.status;
        }
      });
    } else if (
      filterOnlineUsers(userStatus.current, onlineUsers)?.username ===
      onlineUsers?.username &&
      onlineUsers?.status === "online"
    ) {
      const index = userStatus.current.findIndex(
        (user: any) => user.username === onlineUsers?.username
      );
      userStatus.current.map((user: any, i: number) => {
        if (i === index) {
          user.status = onlineUsers?.status;
        }
      });
    }
    if (
      filterOnlineUsers(userStatus.current, onlineUsers)?.username !==
      onlineUsers?.username &&
      onlineUsers?.status === "busy"
    ) {
      userStatus.current.push({
        username: onlineUsers?.username,
        status: onlineUsers?.status,
      });
    }
  }, [onlineUsers]);

  const onClick = (e: any, params: any) => {
    e.stopPropagation(); // don't select this row after
    OnActionClick(e.currentTarget.id, params?.row);
  };

  const SendQRCodeToAssistant = async (user_name: string) => {
    const data = {
      user_name: user_name,
      isConferenceCall: false,
      sessionId: 0
    }
    await apis.instance
      .post(`${SendQRCodeToAssistantRoute}`, data)
      .then((resp: any) => {
        if (resp.data?.success) {
          toast.success(t('responseMsg.msg_qrCode'));
        } else {
          toast.error(resp?.data?.error);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };

  // dynamic scrollbar for grid view start
  const PageTitleRef: any = useRef();
  const tableGridRef: any = useRef();
  const [tableHeight, setTableHeight] = useState<any>();

  const getPageTitleHeight = () => {
    let pageTitleHeight: any = 0;
    if (PageTitleRef && PageTitleRef.current) {
      pageTitleHeight = PageTitleRef.current.clientHeight;
    }
    setTableHeight(window.innerHeight - pageTitleHeight - 82);
  };

  window.addEventListener("resize", getPageTitleHeight);

  // dynamic scrollbar for grid view end

  //#region Action Buttons Click
  const OnActionClick = async (actionId: string, row: any) => {
    switch (actionId) {
      case ButtonActionAdd:
        const result = await checkUserCreationCount(user.orgId, localRole);
        if (result) {
          DefaultAssistant.org_id = user.orgId;
          setAssistantCreationMode(FormAddMode);
          setCurrentAssistantData(DefaultAssistant);
          setIsOpenAddUpdateAssistant(true);
        }
        break;
      case ButtonActionEdit:
        setAssistantCreationMode(FormUpdateMode);
        const assistantToEdit = {
          org_id: row.org_id,
          org_name: "",
          user_id: row.user_id,
          org_admin_title: row.user_title,
          org_admin_fname: row.user_fname ?? "",
          org_admin_lname: row.user_lname ?? "",
          org_admin_email: row.user_email ?? "",
          org_admin_username: row.user_name ?? "",
          org_admin_phone_m: row.phone_number_m,
          org_admin_phone_nm: row.phone_number_nm ?? "",
          org_admin_status: row.user_status,
          orgAdminSendDetailsInEmail: true,
          specialities: [],
          profile_image: row.user_profile_img ?? "",
          country_code: row.country_code ?? countries[0].code,
          set_password_url: "",
          assign_role_to_user: false,
          newAssignedRole: 0,
          isPasswordSet: false
        };
        setCurrentAssistantData(assistantToEdit);
        setIsOpenAddUpdateAssistant(true);
        break;
      case ButtonActionDelete:
        setOpenDeleteAssistantDialog({
          open: true,
          UserID: row.user_id,
          AssistantName: row.user_fname,
        });
        break;
      case ButtonActionSendQRCode:
        const user_name = row.user_name;
        SendQRCodeToAssistant(user_name);
        break;
      case ButtonActionCallSession:
        navigate(navigateToCallSessions, {
          state: {
            username: row.user_name,
            fullname: `${row.user_fname} ${row.user_lname ?? ""}`,
            showAssistantsCallSessions: true,
            userRoleID: ROLE.Assistant,
          },
        });
        break;
      case ButtonActionInitiateCall:
        callingToUser(row);
        break;
      case ButtonReactivate:
        setOpenReactivateUserDialog({
          open: true,
          UserID: row.user_id,
          UserName: (row.user_fname ?? '') + " " +( row.user_lname ?? ""),
        });
        break;
      default:
        break;
    }
  };
  //#endregion Action Buttons Click

  const callingToUser = async (userData: any) => {
    const params = {
      user_id: userData.user_id
    }
    await apis.instance
      .get(`${UserSessionStatusRoute}`, { params })
      .then((resp: any) => {
        if (resp.data.success) {

          const currentUserAvailabilityStatus =
            userStatus.current?.filter(
              (user: any) => user.username === userData.user_name
            )[0]?.status !== "online"
              ? userStatus.current?.filter(
                (user: any) => user.username === userData.user_name
              )[0]?.status !== "busy"
                ? "secondary"
                : "error"
              : "success";
          if (currentUserAvailabilityStatus.toLowerCase() === "secondary") {
            return toast.info(t('responseMsg.msg_userIsOffline'));
          } else if (currentUserAvailabilityStatus.toLowerCase() === "error") {
            return toast.info(t('responseMsg.msg_userIsBusy'));
          }
          const rokidSubscriptionId = userData.subscription_id
          const onCallInitiated = async (isdata:boolean) => {
            const params = { "subscriptionId": rokidSubscriptionId };
            const resp = await apis.instance.get(`${getSubscriptionDataUsage}`, { params })
            if (resp.data.success) {
              if ((resp.data.data.remainingData > 0) || (resp.data.data.isDataUnlimited)) {
                setCallToAssistant({
                  open: true,
                  UserID: userData.user_id,
                  AssistantName: userData.user_fname + " " + userData.user_lname,
                });
                // call to assistant event
                socketRef.current?.emit(CALL_TO_ASSISTANT, {
                  assistantId: userData.user_id,
                  consultantId: user.userId,
                });
                callerToneRef.current?.play();
              } else {
                setAsstDataFinished(true);
              }
            }
          };
          subscriptionDataUsage(onDataFinished, onCallInitiated, false);

        }
      })
      .catch((err) => {
        console.log(err);
      }
      );
  }

  const onDataFinished = (isFinished: boolean) => {
    setDataFinished(isFinished);
  };

  const handleDataExhaustedPopup = () => {
    setDataFinished(false);
    setAsstDataFinished(false);
  }

  return (
    <div>
      <DataLimitPopup
        isOpen={dataFinished || asstDataFinished}
        dataFinished={dataFinished}
        handleDataExhaustedPopup={handleDataExhaustedPopup}
        asstDataFinished={asstDataFinished}
      />
      {openIncomingCallBox()}
      {openCallToAssistantBox()}
      {openReconnectDialog()}
      <Box ref={PageTitleRef} className="page-title-row pb-3">
        <Grid
          container
          alignItems={"center"}
          textAlign={"left"}
          rowSpacing={2}
          columnSpacing={{ xs: 2, md: 0 }}
        >
          <Grid item xs={12} md={auto} mr={auto} sm padding={0} className="page-title-box">
            <Typography
              className="page-title mt-0 mb-0"
              sx={(theme) => theme.typography.h5}
            >
              {" "}
              {localRole === ROLE.OrgAdmin && t('lbl_activeInactive')} {t('lbl_assistants')}{" "}
            </Typography>
          </Grid>
          {
            <Grid item xs={12} md={auto} padding={0}>
              {localRole === ROLE.OrgAdmin && (
                <>
                  <Button id={ButtonActionAdd}
                    onClick={(e) => { onClick(e, null) }}
                    startIcon={<span className='cad-plus'></span>}
                    variant="contained" sx={{ my: .5, mr: 1 }}>
                    {t('lbl_createAssistant')}
                  </Button>
                </>
              )}
              <TextField
                className="cadis-search"
                id="outlined-end-adornment"
                size="small"
                type="search" placeholder="Search"
                sx={{ width: '18ch', my: .5 }}
                value={searchQuery}
                onChange={handleSearchInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className="cad-search"></span>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          }
        </Grid>
      </Box>
      <Box sx={GridStyling} ref={tableGridRef} height={tableHeight}>
        <DataGrid
          components={{
            NoRowsOverlay: GetGridNoRecordMessage,
          }}
          getRowId={(row) => row.user_id}
          rows={assistantData}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={GridRowsPerPageOptions}
          onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
          autoHeight={false}
          autoPageSize={false}
          disableColumnFilter={true}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
        />
      </Box>
      {assistantCreationMode !== FormViewMode ? (
        <AddUpdateOrganizationAdmin
          isOpenAddUpdateAdmin={isOpenAddUpdateAssistant}
          setIsOpenAddUpdateAdminExpandAdminData={setIsOpenAddUpdateAssistant}
          mode={assistantCreationMode}
          currentOrgAdminData={currentAssistantData}
          getOrgUserList={GetAssistantsList}
          userRole={userRole}
          consultantSpecialities={consultantSpecialities}
          userFlow={assistantListFlow}
        />
      ) : null}
      <Dialog
        open={openDeleteAssistantDialog.open}
        onClose={() => {
          return null;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("lbl_confirmation")}</DialogTitle>
        <DialogContent>
          {t('lbl_confirmationForHide')}{" "}
          {openDeleteAssistantDialog.AssistantName}?
        </DialogContent>
        <DialogActions>
          <CadisButton
            onClick={DeleteAssistant}
            variant="contained"
            color="error"
          >
            {t('lbl_yes')}
          </CadisButton>
          <CadisButton
            onClick={handleCloseDeleteAssistant}
            variant="contained"
            color="success"
          >
            {t('lbl_no')}
          </CadisButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AssistantList;
