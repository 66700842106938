import React from "react";
import { Grid, Box, IconButton, InputAdornment, Tooltip, Button, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import CadisTextField from "../Components/Input/CadisTextField";
import { ValidatePhoneNumber } from "./helperFunction";
import CadisLoader from "../Components/Loading/CadisLoader";
import { useSocketContext } from "../context/SocketContext";

interface DialPadProps {
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  phoneNumberErrorMsg: string;
  setPhoneNumberErrorMsg: React.Dispatch<React.SetStateAction<string>>;
  selectedOption: string;
  isNumberValid: boolean;
  setIsNumberValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const DialPad: React.FC<DialPadProps> = ({ phoneNumber, setPhoneNumber, phoneNumberErrorMsg, setPhoneNumberErrorMsg, selectedOption, isNumberValid, setIsNumberValid }) => {
  const { t } = useTranslation();
  const { handleSendInvite, handleCloseInviteDialog, loading } = useSocketContext();

  // const handlePhoneNumberBlur = () => {
  //   const isValid = ValidatePhoneNumber(phoneNumber, setPhoneNumberErrorMsg);
  //   setIsNumberValid(isValid);
  // };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {  // Allows only digits
      setPhoneNumber(value);
    }
    setIsNumberValid(true);
  };

  const handleButtonClick = (num: string | number) => {
    setPhoneNumberErrorMsg('');
    setPhoneNumber((prev: string) => prev + num);
  };

  const handleDelete = () => {
    setPhoneNumber((prev: string) => {
      const newNumber = prev.slice(0, -1);

      if (newNumber === "" || ValidatePhoneNumber(newNumber, setPhoneNumberErrorMsg)) {
        setPhoneNumberErrorMsg("");
        setIsNumberValid(true);
      }
      return newNumber;
    });
  };


  const handleClear = () => {
    setPhoneNumber("");
    setPhoneNumberErrorMsg("");
    setIsNumberValid(true);
  };

  return (
    <Box className="dialpad-box" mb={1}>
      <Box display="flex" gap={1} alignItems="flex-start" sx={{ mb: 2 }}>
        <CadisTextField
          id="phoneNumber"
          name="phoneNumber"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          placeholder="Enter Phone Number"
          error={!isNumberValid}
          helperText={!isNumberValid && selectedOption === 'SMS' ? t(phoneNumberErrorMsg) : ""}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          InputProps={{
            endAdornment: phoneNumber && (
              <InputAdornment position="end">
                <Tooltip title={t('lbl_clear_all')}>
                  <span className="cad-close-circle c-pointer dial-pad-clear-all-icon" onClick={handleClear} ></span>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <IconButton onClick={handleDelete} color="error" aria-label="clear" disabled={!phoneNumber} sx={{ mt: 0.15 }}>
          <span className="cad-delete-back dial-pad-icon"></span>
        </IconButton>
      </Box>
      <Grid container spacing={1} justifyContent={"center"}>
        {["1", "2", "3", "4", "5", "6", "7", "8", "9", "0",].map((num) => (
          <Grid item xs={4} key={num}>
            <IconButton color="primary" className="dial-pad-num-btn"
              onClick={() => (handleButtonClick(num))}>
              {num}
            </IconButton>
          </Grid>
        ))}
      </Grid>
      <Box paddingTop={3.5} paddingX={0} justifyContent={"flex-end"} display={'flex'} columnGap={1}>
        <Button variant="contained" color="success" onClick={handleSendInvite} disabled={loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
          {t('lbl_invite')}
        </Button>
        <Button variant="contained" color="error" onClick={handleCloseInviteDialog}>
          {t('lbl_cancel')}
        </Button>
      </Box>
      <Divider sx={{mt: 2, mb: 0}} />
    </Box>
  );
};

export default DialPad;