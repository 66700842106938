import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { fabric } from "fabric";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CadisDialog from "../../Components/Dialog/CadisDialog";
import { useTimer } from "../../Hooks/useTimerHook";
import { useSocketContext } from "../../context/SocketContext";
import Janus from "../../janus-client/janus";
// ICONS
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CropSquareRoundedIcon from "@mui/icons-material/CropSquareRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MicOffIcon from '@mui/icons-material/MicOff';
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import StopIcon from "@mui/icons-material/Stop";
import TitleSharpIcon from "@mui/icons-material/TitleSharp";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import ZoomOutOutlinedIcon from "@mui/icons-material/ZoomOutOutlined";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import { SubscriptionDataUsage } from "../../Common/api.routes";
import { AST_LEAVE_CONFERENCE_CALL, CALL_END_BY_ASSISTANT, CALL_USER_LEFT, CLEAR_SCREEN, CONFERENCE_CALL_REJECTED, DRAW_FREEHAND, DRAW_OBJECT, DRAW_TEXT, END_CALL, ON_OFF_AUDIO, RECORDING_ENDED, RECORDING_STARTED, REMOVE_OBJECT, REMOVE_TEXT, ROKID_CURRENT_RESOLUTION, ROKID_MAIN_VIEW, ROOM_DESTROYED, WAST_MAIN_VIEW, WEB_AST_LEAVE_CALL } from "../../Common/helper";
import { coordsTransformRatio, isAuthenticated, } from "../../Common/helperFunction";
import CadisButton from "../../Components/Input/CadisButton";
import preventBackNavigation from "../../Components/NavigationBackDialog/PreventBackNavigation";
import apis from "../../HttpConfig/Api";
import DataExhaustedDialog from "./DataLimitPopup";
import ToggleComponent from "./ToggleComponent";

// video call essential variables
var janus = null;
var sfutest = null;
var opaqueId = "videoroom-" + Janus.randomString(12);
var myusername = null;
var myid = null;
var mystream = null;
// We use this other ID just to map our subscriptions to us
var mypvtid = null;

var isRokidOnMain = true;
var isRokidOnMainInConf = false;

var localTracks = {};
var localVideos = 0;
var feeds = [];
var feedStreams = {};
var bitrateTimer = [];
let bitrateTimeout;
var canvas;
let arr_coords = [];
let vWidth;
let vHeight;

// recording variables
let mediaRecorder;
let chunks = [];
let recordMainView = true;

// zoom variable
let zoomLevel = 100;
// annotattion variables
let drawnRect = false;
let drawnCircle = false;

//data consumption variables
let prevBytesSent = 0;
var webassistantIntervalId = null;
var rokidIntervalId = null;
var dataSent = 0;
var wastDataReceived = 0;
var rokidDataReceived = 0;
var conIntervalDataSent = 0;
var wastIntervalDataSent = 0;
var rokidIntervalDataSent = 0;
var intervalId = 0;
var isRunning = sessionStorage.setItem("isRunning", JSON.stringify(true));

function useEventListener(socketRef, eventName, callback) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    const eventHandler = (data) => {
      callbackRef.current(data);
    };
    socketRef.current?.on(eventName, eventHandler);

    return () => {
      socketRef.current?.off(eventName, eventHandler);
      sessionStorage.removeItem("userTyping")
    };
  }, [socketRef, eventName]);

  // Update the callback if it changes
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  return callbackRef;
}

const CallScreen = () => {
  const { socketRef, janusURL, failedPong, callToAssistantRef, roomDetails, sessionIdRef, isConferenceCall, isNormalCall, isMuteRokid, isWebAsstMuted, wastInviteSent, rokidInviteSent, isWAConferenceCallRef, callJoinToneRef, setRoomDetails, setInCommingCall, setInviteDialog, setSelfEmailAddress, setJanusURL, setCallToAssistant, setUserDetails, openInviteDialog, openReconnectDialog, setIsConferenceCall, setRokidInviteDialog, openRokidUserInviteDialog, setEmailAddress, setPhoneNumber, SetIsMuteRokid, SetIsWebAsstMuted, setWastInviteSent, setRokidInviteSent } = useSocketContext(); // socket context customeHook
  const { seconds, minutes, hours, handleStart, handleReset } = useTimer(); // timer customeHook
  const navigate = useNavigate();
  const { state } = useLocation();
  const isRecording = sessionStorage.getItem("isRecording") === "true";
  const isWebAstJoinCheck = sessionStorage.getItem("isWebAssistantJoin") === "true";
  const { navigationBackPopup, setNavigationBackPopup } = preventBackNavigation('/dashboard/assistants');
  // refs for glass video referense
  const remoteVideoRef1 = useRef();
  const remoteVideoRef2 = useRef();
  const remoteAudioRef1 = useRef();
  const remoteAudioRef2 = useRef();
  const localVideoRef = useRef();
  const canvasTxtRef = useRef();
  const [notes, setNotes] = useState("");
  const [isRemoteVideo, setIsRemoteVideo] = useState(false);
  const [isPluginAttached, setIsPluginAttached] = useState(false);
  const [annotate, setAnnotate] = useState(false);
  const [mute, setMute] = useState(false);
  const [recording, setRecording] = useState(isRecording);
  const [takeNotes, setTakeNotes] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState('');
  const [remoteAudioTrack, setRemoteAudioTrack] = useState();
  const [localAudioTrack, setLocalAudioTrack] = useState();
  const [isSend, setIsSend] = useState(false);
  const [userLeftAlert, setUserLeftAlert] = useState(false);
  const [isButtonSelected, setIsButtonSelected] = useState('');
  const [isRokidAstJoin, setIsRokidAstJoin] = useState(false);
  const [isWebAstJoin, setIsWebAstJoin] = useState(isWebAstJoinCheck);
  const [container1, setContainer1] = useState('container1');
  const [container2, setContainer2] = useState('container2');
  const [isMainView, setIsMainView] = useState(true);
  const [showAnnotateBtn, setShowAnnotateBtn] = useState(false);
  const [showMuteIcon, setShowMuteIcon] = useState(false);
  const [recordingPopup, setRecordingPopup] = useState(false);
  const [consAudio, setConsAudio] = useState(null);
  const [recordingSession, setRecordingSession] = useState(sessionStorage.getItem("isRecording") === "true");
  const [dataUsageTimer, setDataUsageTimer] = useState(0);
  const [dataExhausted, setDataExhausted] = useState(false);
  const [showDataExhausted, setShowDataExhausted] = useState(true);
  const [remainingDataPercentage, setRemainingDataPercentage] = useState("");
  const [showDataText, setShowDataText] = useState(false);
  const [textAnnotationError, setTextAnnotationError] = useState(false);
  const user = isAuthenticated();
  const { t } = useTranslation();
  let isDrawing = false;

  // const containerRef = useRef();

  const handleWebAstLeaveCall = (data) => {
    toast.info(data + ' has left the call.');
    setIsWebAstJoin(false);
    sessionStorage.setItem("isWebAssistantJoin", JSON.stringify(false));
    sessionStorage.removeItem("webAssitName");
    SetIsWebAsstMuted(false);
    if (recording && isMainView) {
      handleStopRecording();
      sessionStorage.setItem("isRecording", JSON.stringify(false));
      setRecording(false);
      sessionStorage.setItem("isRecording", JSON.stringify(false));
      setRecording(false);
      setRecordingSession(false);
    }
  };

  const handleRokidAstLeaveCall = (data) => {
    toast.info(data + ' has left the call.');
    canvas?.clear();
    setNotes('');
    setTakeNotes(false);
    setSelectedIcon('');
    setIsRokidAstJoin(false);
    setAnnotate(false);
    SetIsMuteRokid(false);
    sessionStorage.setItem("isRokidAstJoin", JSON.stringify(false));
    // if (!isMainView) {
    //   setRecordingPopup(false);
    // }
    if (recording && !isMainView) {
      handleStopRecording();
      sessionStorage.setItem("isRecording", JSON.stringify(false));
      setRecording(false);
      sessionStorage.setItem("isRecording", JSON.stringify(false));
      setRecording(false);
      setRecordingSession(false);
    }
  };

  const handleRokidRejectedCall = (data) => {
    toast.info(data + ' has rejected the call.');
    setIsRokidAstJoin(false)
  };

  const webAstLeaveCallCallbackRef = useEventListener(socketRef, WEB_AST_LEAVE_CALL, handleWebAstLeaveCall);
  const rokidAstLeaveCallCallbackRef = useEventListener(socketRef, AST_LEAVE_CONFERENCE_CALL, handleRokidAstLeaveCall);
  const rokidRejectedConferenceCall = useEventListener(socketRef, CONFERENCE_CALL_REJECTED, handleRokidRejectedCall);

  useEffect(() => {

    // socket event on if glass user end call
    socketRef.current?.on(ROOM_DESTROYED, async () => {
      if (mediaRecorder?.state === "recording") {
        mediaRecorder?.stop();
      }
      await sfutest?.hangup();
      await janus?.destroy();
      handleReset();
      navigate("/callquality");
    });

    //Changed event from ROOM_DESTROYED to CALL_END_BY_ASSISTANT socket event if glass user end call
    socketRef.current?.on(CALL_END_BY_ASSISTANT, async (data) => {
      if (recording) {
        handleStopRecording()
      }
      await sfutest?.hangup();
      await janus?.destroy();
      handleReset();
      toast.info(data + " has ended the call.")
      navigate("/callquality");
      SetIsMuteRokid(false);
    });

    socketRef.current?.on(CALL_USER_LEFT, () => {
      canvas.clear();
      handleStopRecording();
      setRecording(false);
      setUserLeftAlert(true);
      setIsRemoteVideo(false);
    })

    socketRef.current?.on(ROKID_CURRENT_RESOLUTION, (data) => {
      sessionStorage.setItem("rokidWidth", JSON.stringify(data.width));
      sessionStorage.setItem("rokidHeight", JSON.stringify(data.height));
    });

    const username = JSON.parse(sessionStorage.getItem('username'));
    let userRole = parseInt(JSON.parse(sessionStorage.getItem('role')));
    setUserDetails({ username: username, userrole: userRole });
    if (!socketRef.current && (userRole === 3) && apis.socketURL) {
      socketRef.current = io(apis.socketURL);
    }
    const conferenceCallStarted = sessionStorage.getItem("isConferenceCall") === "true"
    if (conferenceCallStarted) {
      isRokidOnMainInConf = false;
      isRokidOnMain = false;
    }

    return () => {
      setInCommingCall(false);
      setRoomDetails(null);
      setJanusURL(null);
      setCallToAssistant({ 'open': false, 'UserID': null, 'AssistantName': '' });
      zoomLevel = 100;
      callToAssistantRef.current = { open: false, UserID: null, AssistantName: '' };
      socketRef.current?.off(ROOM_DESTROYED);
      socketRef.current?.off(CALL_END_BY_ASSISTANT);
      socketRef.current?.off(CALL_USER_LEFT);
      socketRef.current?.off(WEB_AST_LEAVE_CALL)
      socketRef.current?.off(CONFERENCE_CALL_REJECTED)
      handleStopRecording();
      clearTimeout(bitrateTimeout);
      bitrateTimeout = null;
      canvas.clear();
      canvasTxtRef.current = null;
    };
  }, [socketRef.current]);

  useEffect(() => {
    const isWebAstJoinCheck = (sessionStorage.getItem("isWebAssistantJoin") === "true") ?? false;
    const isRokidAstJoinCheck = (sessionStorage.getItem("isRokidAstJoin") === "true") ?? false;
    const recordingSessionState = sessionStorage.getItem("isRecording") === "true";

    if ((recordingSessionState && isWebAstJoinCheck) || (recordingSessionState && isRokidAstJoinCheck)) {
      handleStopRecording();
      handleStartRecording();
    }
  }, [isWebAstJoin, isRokidAstJoin])

  useEffect(() => {
    // set runtime W and H to canvas
    let pvideo_container = document.querySelector('#container1');
    if (pvideo_container && pvideo_container.getBoundingClientRect) {
      vWidth = pvideo_container.getBoundingClientRect().width;
      vHeight = pvideo_container.getBoundingClientRect().height;
    }
    // fabric canvas
    canvas = new fabric.Canvas("canvas", {
      width: vWidth,
      height: vHeight,
    });

    // when user move any object on canvas --> func runs
    canvas.on({
      "object:modified": function (e) {
        let active_shape = canvas.getActiveObject();
        let acoords = active_shape.aCoords;
        let type = e.target.get("type");
        const isWebAssistant = sessionStorage.getItem("isWebAssistant") === "true";
        const isConferenceCall = sessionStorage.getItem("isConferenceCall") === "true";
        let webAssistantViewFlag = (isWebAssistant && isConferenceCall) ? 1 : 0;
        if (type === "circle") {
          socketRef.current.emit(DRAW_OBJECT, {
            webAssistantViewFlag,
            type,
            coords: {
              tl: {
                x: acoords.tl.x * (coordsTransformRatio(vWidth, vHeight).transformXRatio),
                y: acoords.tl.y * (coordsTransformRatio(vWidth, vHeight).transformYRatio),
              },
              tr: { x: acoords.tr.x, y: acoords.tr.y },
              bl: { x: acoords.bl.x, y: acoords.bl.y },
              br: { x: acoords.br.x, y: acoords.br.y },
            },
            dimensions: {
              w: active_shape.radius * e.target.scaleX * 2 * (coordsTransformRatio(vWidth, vHeight).transformXRatio),
              h: active_shape.radius * e.target.scaleY * 2 * (coordsTransformRatio(vWidth, vHeight).transformXRatio),
            },
            sessionId: sessionIdRef.current
          });
        } else if (type === "rect") {
          socketRef.current.emit(DRAW_OBJECT, {
            webAssistantViewFlag,
            type,
            coords: {
              tl: {
                x: acoords.tl.x * (coordsTransformRatio(vWidth, vHeight).transformXRatio),
                y: acoords.tl.y * (coordsTransformRatio(vWidth, vHeight).transformYRatio),
              },
              tr: { x: acoords.tr.x, y: acoords.tr.y },
              bl: { x: acoords.bl.x, y: acoords.bl.y },
              br: { x: acoords.br.x, y: acoords.br.y },
            },
            dimensions: {
              w: active_shape.width * e.target.scaleX * (coordsTransformRatio(vWidth, vHeight).transformXRatio),
              h: active_shape.height * e.target.scaleY * (coordsTransformRatio(vWidth, vHeight).transformYRatio),
            },
            sessionId: sessionIdRef.current
          });
        }
      },
    });

  }, []);

  const handleResizeCanvas = () => {
    let pvideo_container = document.querySelector('#container1');
    if (pvideo_container && pvideo_container.getBoundingClientRect) {
      vWidth = pvideo_container.getBoundingClientRect().width;
      vHeight = pvideo_container.getBoundingClientRect().height;
    }
    canvas.setDimensions({ width: vWidth, height: vHeight });
    setTimeout(() => {
      if ((sessionStorage.getItem("userTyping") !== "true") && (sessionStorage.getItem("userTyping") !== "textSend")) {
        canvas?.clear();
        socketRef.current?.emit(CLEAR_SCREEN, sessionIdRef.current);
        setNotes('');
        setTakeNotes(false);
        setSelectedIcon('');
      }
    }, 500);
  }

  // by Deepika
  const containerRef = useRef(null);
  const innerContainerRef = useRef(null);

  const maintainAspectRatio = () => {
    const videoContainer = containerRef.current;
    const innerContainer = innerContainerRef.current;

    const containerWidth = videoContainer.clientWidth;
    const containerHeight = videoContainer.clientHeight;

    // Calculate the aspect ratio
    const aspectRatio = 9 / 16; // 16:9 aspect ratio
    const containerAR = containerHeight / containerWidth;

    // Compare width and height, and adjust accordingly
    if (containerAR > aspectRatio && (isRokidOnMain || isRokidOnMainInConf)) {
      // Set inner div width to 100%
      innerContainer.style.width = '100%';
      innerContainer.style.height = 'auto';
    } else if (containerAR < aspectRatio && (isRokidOnMain || isRokidOnMainInConf)) {
      // Set inner div height to 100%
      innerContainer.style.width = 'auto';
      innerContainer.style.height = '100%';
    }
    else {
      innerContainer.style.width = '100%';
      innerContainer.style.height = '100%';
    }
  };
  // by Deepika - ends here

  useEffect(() => {
    // if roomId then initalize janus client 
    if (roomDetails?.roomId && !isPluginAttached) {
      Janus.init({
        debug: "all",
        callback: function () {
          janusStart(janusURL, roomDetails.turnServer);
        },
      });
    }

    // if roomId && janus plugin is attached then join Janus room 
    if (roomDetails?.roomId && isPluginAttached) {
      try {
        var join = {
          request: "join",
          room: roomDetails.roomId,
          ptype: "publisher",
          display: 'consultant',
        };
        sfutest?.send({
          message: join,
          success: (data) => { },
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [roomDetails, isPluginAttached, janusURL]);

  // useEffect for display network status and display popup when user click on browser window back button.
  useEffect(() => {
    if (failedPong === 2) toast.warning(t('responseMsg.msg_unstableConnection'));
    if (failedPong === 5) {
      toast.error(t('responseMsg.msg_connectionLostWithServer'));
      navigate('/dashboard/assistants');
    }
  }, [failedPong, navigate]);

  // commenting code but leaving as is for now - may need
  // useEffect(() => {
  //   let timeoutId;
  //   if (navigationBackPopup || recordingPopup) {
  //     timeoutId = setTimeout(() => { // Set a timeout for 30 seconds
  //       setNavigationBackPopup(false); // Close the navigate back popup after 30 seconds if there's no user interaction 
  //       setRecordingPopup(false); // close the recording ended popup after 30 seconds if no user interaction
  //     }, 30000);
  //   }
  //   // Clean up the timeout if the popup is closed before timeout
  //   return () => clearTimeout(timeoutId);
  // }, [navigationBackPopup, recordingPopup]);

  useEffect(() => {
    intervalId = setInterval(() => {
      dataConsumption(dataSent, false);
      setDataUsageTimer(dataUsageTimer + 1);
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);

  function onMouseDown(e) {
    const pointer = canvas.getPointer(e);
    if (isDrawing) {
      arr_coords.push({
        x: pointer.x * (coordsTransformRatio(vWidth, vHeight).transformXRatio),
        y: pointer.y * (coordsTransformRatio(vWidth, vHeight).transformYRatio),
      });
    }
  }

  function onMouseUp(e) {
    const pointer = canvas.getPointer(e);
    const isWebAssistant = sessionStorage.getItem("isWebAssistant") === "true";
    const isConferenceCall = sessionStorage.getItem("isConferenceCall") === "true";
    let webAssistantViewFlag = (isWebAssistant && isConferenceCall) ? 1 : 0;
    if (canvas.isDrawingMode) {
      arr_coords.push({
        x: pointer.x * (coordsTransformRatio(vWidth, vHeight).transformXRatio),
        y: pointer.y * (coordsTransformRatio(vWidth, vHeight).transformYRatio),
      });
      socketRef.current?.emit(DRAW_FREEHAND, { coords: arr_coords, sessionId: sessionIdRef.current, webAssistantViewFlag });
      arr_coords = [];
      canvas.isDrawingMode = false;
      var objects = canvas.getObjects();
      var paths = objects.filter(function (obj) {
        return obj instanceof fabric.Path;
      });
      paths[0].lockMovementX = true;
      paths[0].lockMovementY = true;
    }
  }

  function drawRealTime(e, pointer) {
    if (isDrawing) {
      arr_coords.push({
        x: pointer.x * (coordsTransformRatio(vWidth, vHeight).transformXRatio),
        y: pointer.y * (coordsTransformRatio(vWidth, vHeight).transformYRatio),
      });
    }
  }

  var acodec = null;
  var vcodec = null;
  var doDtx = false;
  var subscriber_mode = false;
  var use_msid = false;

  const janusStart = (server, turnServer) => {
    // calls the Janus function in janus.js file
    janus = new Janus({
      server: server,
      echo_cancellation: true,
      iceServers: turnServer,
      iceTransportPolicy: "all", // It should work with relay
      success: () => {
        // attach video room plugin
        janus.attach({
          plugin: "janus.plugin.videoroom",
          opaqueId: opaqueId,
          success: (pluginHandle) => {
            // after successfully attached it return pluginHandle
            sfutest = pluginHandle;
            setIsPluginAttached(true);
            // Janus.log(
            //   "Plugin attached! (" +
            //     sfutest.getPlugin() +
            //     ", id=" +
            //     sfutest.getId() +
            //     ")"
            // );
          },
          error: (error) => {
            console.error("  -- Error attaching plugin...", error);
          },
          iceState: function (state) {
            Janus.log("ICE state changed to " + state);
          },
          mediaState: function (medium, on, mid) {
            Janus.log(
              "Janus " +
              (on ? "started" : "stopped") +
              " receiving our " +
              medium +
              " (mid=" +
              mid +
              ")"
            );
          },
          slowLink: function (uplink, lost, mid) {
            Janus.warn(
              "Janus reports problems " +
              (uplink ? "sending" : "receiving") +
              " packets on mid " +
              mid +
              " (" +
              lost +
              " lost packets)"
            );
          },
          onmessage: function (msg, jsep) {
            Janus.debug(" ::: Got a message (publisher) :::", msg);
            var event = msg["videoroom"];
            if (event) {
              if (event === "joined") {
                myid = msg["id"];
                mypvtid = msg["private_id"];

                if (subscriber_mode) {
                } else {
                  publishOwnFeed(true);
                }
                if (msg["publishers"].length !== 0) {
                }
                // Any new feed to attach to?
                if (msg["publishers"]) {
                  var list = msg["publishers"];
                  for (var f in list) {
                    if (list[f]["dummy"]) continue;
                    var id = list[f]["id"];
                    let streams = list[f]["streams"];
                    var display = list[f]["display"];
                    for (var i in streams) {
                      var stream = streams[i];
                      stream["id"] = id;
                      stream["display"] = display;
                    }
                    feedStreams[id] = streams;
                    newRemoteFeed(id, display, streams);
                  }
                }
              } else if (event === "destroyed") {
                // The room has been destroyed
              } else if (event === "event") {
                // Any info on our streams or a new feed to attach to?
                if (msg["streams"]) {
                  var streams = msg["streams"];
                  for (let i in streams) {
                    let stream = streams[i];
                    stream["id"] = myid;
                    stream["display"] = myusername;
                  }
                  feedStreams[myid] = streams;
                } else if (msg["publishers"]) {
                  let list = msg["publishers"];
                  for (let f in list) {
                    if (list[f]["dummy"]) continue;
                    let id = list[f]["id"];
                    let display = list[f]["display"];
                    let streams = list[f]["streams"];
                    for (let i in streams) {
                      let stream = streams[i];
                      stream["id"] = id;
                      stream["display"] = display;
                    }
                    feedStreams[id] = streams;
                    newRemoteFeed(id, display, streams);
                  }
                } else if (msg["leaving"]) {
                  // One of the publishers has gone away?
                  var leaving = msg["leaving"];
                  // Janus.log("Publisher left: " + leaving);
                  // toast.warning('User has been left');
                  var remoteFeed = null;
                  for (let i = 1; i < 6; i++) {
                    if (feeds[i] && feeds[i].rfid === leaving) {
                      remoteFeed = feeds[i];
                      break;
                    }
                  }
                  if (remoteFeed) {
                    feeds[remoteFeed.rfindex] = null;
                    remoteFeed.detach();
                  }
                  delete feedStreams[leaving];
                } else if (msg["unpublished"]) {
                  // One of the publishers has unpublished?
                  var unpublished = msg["unpublished"];
                  // Janus.log("Publisher left: unpublished " + unpublished);
                  if (unpublished === "ok") {
                    // That's us
                    sfutest.hangup();
                    return;
                  }
                  let remoteFeed = null;
                  for (let i = 1; i < 6; i++) {
                    if (feeds[i] && feeds[i].rfid === unpublished) {
                      remoteFeed = feeds[i];
                      break;
                    }
                  }
                  if (remoteFeed) {
                    feeds[remoteFeed.rfindex] = null;
                    remoteFeed.detach();
                  }
                  delete feedStreams[unpublished];
                } else if (msg["error"]) {
                  if (msg["error_code"] === 426) {
                    // This is a "no such room" error: give a more meaningful description
                  } else {
                    console.error(msg["error"]);
                  }
                }
              }
            }
            if (jsep) {
              Janus.debug("Handling SDP as well...", jsep);
              // set answerSDP to remoteSDP
              sfutest.handleRemoteJsep({ jsep: jsep });
              // Check if any of the media we wanted to publish has
              // been rejected (e.g., wrong or unsupported codec)
              var audio = msg["audio_codec"];
              if (
                mystream &&
                mystream.getAudioTracks() &&
                mystream.getAudioTracks().length > 0 &&
                !audio
              ) {
                // Audio has been rejected
              }
              var video = msg["video_codec"];
              if (
                mystream &&
                mystream.getVideoTracks() &&
                mystream.getVideoTracks().length > 0 &&
                !video
              ) {
                // Video has been rejected
                // Hide the webcam video
              }
            }
          },
          onlocaltrack: function (track, on) {
            // We use the track ID as name of the element, but it may contain invalid characters
            var trackId = track.id.replace(/[{}]/g, "");
            if (!on) {
              // Track removed, get rid of the stream and the rendering
              var stream = localTracks[trackId];
              if (stream) {
                try {
                  var tracks = stream.getTracks();
                  for (var i in tracks) {
                    var mst = tracks[i];
                    if (mst !== null && mst !== undefined) mst.stop();
                  }
                } catch (e) { }
              }
              if (track.kind === "video") {
                localVideos--;
                if (localVideos === 0) {
                  // No video, at least for now: show a placeholder
                }
              }
              delete localTracks[trackId];
              return;
            }

            // If we're here, a new track was added
            var stream = localTracks[trackId];
            if (stream) {
              // We've been here already
              return;
            }

            // append mute button functionality remaining line 321- 329
            if (isConferenceCall) {
              // Conference call logic
              if (track.kind === "audio") {
                setLocalAudioTrack(track);
                let audioStream = new MediaStream([track]);
                localTracks[trackId] = audioStream;
                if (localVideos === 0) {
                  // No video, at least for now: show a placeholder
                }
              } else if (track.kind === "video") {
                // Handle video track
                localVideos++;
                let videoStream = new MediaStream([track]);
                localTracks[trackId] = videoStream;
                if (localVideoRef.current) {
                  localVideoRef.current.srcObject = videoStream;
                }
              }
            } else {
              // Non-conference call logic
              if (track.kind === "audio") {
                setLocalAudioTrack(track);
                // Ignore local audio tracks, they'd generate echo anyway
                let stream = new MediaStream([track]);
                localTracks[trackId] = stream;
                if (localVideos === 0) {
                  // No video, at least for now: show a placeholder
                }
              } else if (track.kind === "video") {
                // New video track: create a stream out of it
                localVideos++;
                let stream = new MediaStream([track]);
                localTracks[trackId] = stream;
              }
            }
            if (
              sfutest.webrtcStuff.pc.iceConnectionState !== "completed" &&
              sfutest.webrtcStuff.pc.iceConnectionState !== "connected"
            ) {
              //publishing
            }
          },
        });
      },
      error: function (error) {
        Janus.error(error);
        console.error(error);
      },
      destroyed: function () {
        setIsConferenceCall(false)
        sessionStorage.setItem('isConferenceCall', JSON.stringify(false));
        console.log("janus destroyed");
        clearInterval(webassistantIntervalId);
        clearInterval(rokidIntervalId);
      },
    });
  };

  // Publish our stream
  // We want sendonly audio 
  function publishOwnFeed(useAudio) {
    let tracks = [];
    if (useAudio) tracks.push({ type: "audio", capture: true, recv: false });
    tracks.push({
      type: "video",
      capture: isConferenceCall ? true : false,
      recv: false,
      simulcast: true,
    });

    sfutest.createOffer({
      tracks: tracks,
      customizeSdp: function (jsep) {
        // If DTX is enabled, munge the SDP
        if (doDtx) {
          jsep.sdp = jsep.sdp.replace(
            "useinbandfec=1",
            "useinbandfec=1;usedtx=1"
          );
        }
      },
      success: function (jsep) {
        Janus.debug("Got publisher SDP!", jsep);

        var publish = { request: "configure", audio: useAudio, video: true };
        if (acodec) publish["audiocodec"] = acodec;
        if (vcodec) publish["videocodec"] = vcodec;
        sfutest.send({ message: publish, jsep: jsep });
        // Calculate bitrate
        calculateBitrate();
      },
      error: function (error) {
        Janus.error("WebRTC error:", error);
        if (useAudio) {
          publishOwnFeed(false);
        } else {
          console.error("WebRTC error... " + error.message);
        }
      },
    });
  }

  const calculateBitrate = () => {
    setInterval(() => {
      // Get WebRTC statistics
      const pc = sfutest.webrtcStuff.pc;
      if (!pc) {
        return;
      }
      pc.getStats(null)
        .then(stats => {
          // Iterate through stats to find the bitrate
          stats.forEach(report => {
            if (report.type === 'outbound-rtp') {
              const bytesSent = report.bytesSent;
              if (bytesSent >= prevBytesSent) {
                const bytesSentDelta = bytesSent - prevBytesSent;
                dataSent = ((prevBytesSent + bytesSentDelta) / (1024 * 1024));
                prevBytesSent = bytesSent;
              } else {
                // Handle unexpected decrease in bytesSent (optional)
                console.warn('Unexpected decrease in bytesSent');
              }
            }
          })
        })
        .catch(error => {
          console.error('Failed to get WebRTC statistics:', error);
        });
    }, 1000); // Fetch statistics every second
  }


  // another participant feed display on web
  function newRemoteFeed(id, display, streams) {

    // handleStart();
    var remoteFeed = null;
    var webAssistantRemoteFeed = null;
    var rokidRemoteFeed = null;
    if (!streams) streams = feedStreams[id];
    janus.attach({
      plugin: "janus.plugin.videoroom",
      opaqueId: opaqueId,
      success: function (pluginHandle) {
        remoteFeed = pluginHandle;
        remoteFeed.remoteTracks = {};
        remoteFeed.remoteVideos = 0;
        remoteFeed.simulcastStarted = true;
        // Prepare the streams to subscribe to, as an array: we have the list of
        // streams the feed is publishing, so we can choose what to pick or skip
        var subscription = [];
        for (var i in streams) {
          var stream = streams[i];
          // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
          if (
            stream.type === "video" &&
            Janus.webRTCAdapter.browserDetails.browser === "safari" &&
            (stream.codec === "vp9" ||
              (stream.codec === "vp8" && !Janus.safariVp8))
          ) {
            continue;
          }
          subscription.push({
            feed: stream.id, // This is mandatory
            mid: stream.mid, // This is optional (all streams, if missing)
          });
          // FIXME Right now, this is always the same feed: in the future, it won't
          remoteFeed.rfid = stream.id;
          remoteFeed.rfdisplay = escapeXmlTags(stream.display);

          if (remoteFeed.rfdisplay == "webassistant") {
            webAssistantRemoteFeed = { ...remoteFeed }
          } else {
            rokidRemoteFeed = { ...remoteFeed }
          }
        }
        // We wait for the plugin to send us an offer
        var subscribe = {
          request: "join",
          room: parseInt(roomDetails.roomId),
          ptype: "subscriber",
          streams: subscription,
          use_msid: use_msid,
          private_id: mypvtid,
        };
        remoteFeed.send({ message: subscribe });
      },
      error: function (error) {
        Janus.error("  -- Error attaching plugin...", error);
      },
      iceState: function (state) {
        Janus.log(
          "ICE state (feed #" + remoteFeed.rfindex + ") changed to " + state
        );
      },
      webrtcState: function (on) {

        Janus.log(
          "Janus says this WebRTC PeerConnection (feed #" +
          remoteFeed.rfindex +
          ") is " +
          (on ? "up" : "down") +
          " now"
        );
      },
      slowLink: function (uplink, lost, mid) {
        Janus.warn(
          "Janus reports problems " +
          (uplink ? "sending" : "receiving") +
          " packets on mid " +
          mid +
          " (" +
          lost +
          " lost packets)"
        );
      },
      onmessage: function (msg, jsep) {
        Janus.debug(" ::: Got a message (subscriber) :::", msg);
        var event = msg["videoroom"];
        if (msg["error"]) {
          console.error(msg["error"]);
        } else if (event) {
          if (event === "attached") {
            // Subscriber created and attached
            for (var i = 1; i < 6; i++) {
              if (!feeds[i]) {
                feeds[i] = remoteFeed;
                remoteFeed.rfindex = i;
                break;
              }
            }
          } else if (event === "event") {
            // Check if we got a simulcast-related event from this publisher
            var substream = msg["substream"];
            var temporal = msg["temporal"];
            if (
              (substream !== null && substream !== undefined) ||
              (temporal !== null && temporal !== undefined)
            ) {
              if (!remoteFeed.simulcastStarted) {
                remoteFeed.simulcastStarted = true;
                // Add some new buttons
              }
              // We just received notice that there's been a switch, update the buttons
              // updateSimulcastButtons(remoteFeed.rfindex, substream, temporal);
            }
          } else {
            // What has just happened?
          }
        }
        if (jsep) {
          var stereo = jsep.sdp.indexOf("stereo=1") !== -1;

          // Answer and attach
          remoteFeed.createAnswer({
            jsep: jsep,
            // We only specify data channels here, as this way in
            // case they were offered we'll enable them. Since we
            // don't mention audio or video tracks, we autoaccept them
            // as recvonly (since we won't capture anything ourselves)
            tracks: [{ type: "data" }],
            customizeSdp: function (jsep) {
              if (stereo && jsep.sdp.indexOf("stereo=1") === -1) {
                // Make sure that our offer contains stereo too
                jsep.sdp = jsep.sdp.replace(
                  "useinbandfec=1",
                  "useinbandfec=1;stereo=1"
                );
              }
            },
            success: function (jsep) {
              Janus.debug("Got SDP!", jsep);
              var body = {
                request: "start",
                room: parseInt(roomDetails.roomId),
              };
              // var body = { request: "start", room: parseInt(myroom) };
              remoteFeed.send({ message: body, jsep: jsep });
            },
            error: function (error) {
              Janus.error("WebRTC error:", error);
            },
          });
        }
      },
      onlocaltrack: function (track, on) {
        // The subscriber stream is recvonly, we don't expect anything here
      },
      onremotetrack: function (track, mid, on) {
        if (!on) {
          // Track removed, get rid of the stream and the rendering
          if (track.kind === "video") {
            remoteFeed.remoteVideos--;
            if (remoteFeed.remoteVideos === 0) {
              //"Track removed, get rid of the stream and the rendering"
            }
          }
          delete remoteFeed.remoteTracks[mid];
          return;
        }
        // If we're here, a new track was added
        if (remoteFeed.spinner) {
          remoteFeed.spinner.stop();
          remoteFeed.spinner = null;
        }
        if (track.kind === "audio") {
          setRemoteAudioTrack(track);
          // New audio track: create a stream out of it, and use a hidden <audio> element
          let stream = new MediaStream([track]);
          remoteFeed.remoteTracks[mid] = stream;
          // attach remote video stream
          if (isConferenceCall) {
            const targetAudioRef = remoteFeed.rfdisplay == "webassistant" ? remoteAudioRef1 : remoteAudioRef2;
            Janus.attachMediaStream(targetAudioRef.current, stream);
          }
          else
            Janus.attachMediaStream(remoteAudioRef1.current, stream);
          if (remoteFeed.remoteVideos === 0) {
            // No video, at least for now: show a placeholder
          }
        } else {
          // New video track: create a stream out of it
          remoteFeed.remoteVideos++;
          let stream = new MediaStream([track]);
          remoteFeed.remoteTracks[mid] = stream;

          // Attach remote video stream to the correct useRef reference
          if (isConferenceCall) {
            const targetRef = remoteFeed.rfdisplay == "webassistant" ? remoteVideoRef1 : remoteVideoRef2;
            Janus.attachMediaStream(targetRef.current, stream);

            if (remoteFeed.rfdisplay === "webassistant") {
              webassistantBitrate(webAssistantRemoteFeed);
              setIsWebAstJoin(true);
              sessionStorage.setItem("isWebAssistantJoin", JSON.stringify(true));
              const keyExists = sessionStorage.getItem('isWebAssistant') !== null;
              if (!keyExists) {
                sessionStorage.setItem("isWebAssistant", JSON.stringify(true));
              }
            } else {
              rokidBitrate(rokidRemoteFeed);
              setIsRokidAstJoin(true);
              sessionStorage.setItem("isRokidAstJoin", JSON.stringify(true));
            }
          }
          else {
            Janus.attachMediaStream(remoteVideoRef1.current, stream);
            rokidBitrate(rokidRemoteFeed);
          }
          
          if (callJoinToneRef.current) {
            callJoinToneRef.current.currentTime = 0; // Reset to start
            callJoinToneRef.current.play();
          }
          // Consultant audio is transmitted to the assistant who is on main view in consultant screen
          //while Rokid or Web assistant joins the call we are calling handleConsultantAudioTransmit
          if (isConferenceCall) {
            handleConsultantAudioTransmit();
          }

          setIsRemoteVideo(true);
          handleStart();
          // Note: we'll need this for additional videos too

          if (!bitrateTimer[remoteFeed.rfindex]) {
            // description
            // bitrateTimer[remoteFeed.rfindex] = bitrateTimeout = setInterval(function() {
            //   let bitrate = remoteFeed.getBitrate();
            //   let bitspersec = document.getElementById('biterate');
            //   if(bitspersec) bitspersec.innerText = bitrate;
            // }, 1000);
          }
        }
      },
      oncleanup: function () {
        remoteFeed.remoteTracks = {};
        remoteFeed.remoteVideos = 0;
      },
    });
  }

  function webassistantBitrate(remotefeed) {
    if (webassistantIntervalId) {
      clearInterval(webassistantIntervalId);
    }
    webassistantIntervalId = setInterval(function () {
      if (!remotefeed) {
        return;
      }
      let bitrate = remotefeed.getBitrate();
      let numericBitrate = parseFloat(bitrate) / (1000 * 8);
      if (numericBitrate !== null) {
        if (!isNaN(numericBitrate)) {
          wastDataReceived += numericBitrate;
        }
      }
    }, 1000);
  }

  function rokidBitrate(remotefeed) {
    if (rokidIntervalId) {
      clearInterval(rokidIntervalId);
    }
    rokidIntervalId = setInterval(function () {
      if (!remotefeed) {
        return;
      }
      let bitrate = remotefeed.getBitrate();
      let numericBitrate = parseFloat(bitrate) / (1000 * 8);
      if (numericBitrate !== null) {
        if (!isNaN(numericBitrate)) {
          rokidDataReceived += numericBitrate;
        }
      }
    }, 1000);
  }

  // Helper to escape XML tags
  function escapeXmlTags(value) {
    if (value) {
      var escapedValue = value.replace(new RegExp("<", "g"), "&lt");
      escapedValue = escapedValue.replace(new RegExp(">", "g"), "&gt");
      return escapedValue;
    }
  }

  const getLocalMicStream = async () => {
    try {
      const constraints = { audio: true, video: false }; // Request only audio
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      setConsAudio(stream);
      return stream;
    } catch (error) {
      console.error("Error accessing local microphone:", error);
      return null;
    }
  }

  // RECORDING HANDLER
  const handleStartRecording = async () => {
    let webAssistantFlag = (isMainView && isWebAstJoin) ? 1 : 0;
    const isWebAstJoinCheck = (sessionStorage.getItem("isWebAssistantJoin") === "true") ?? false;
    const isRokidAstJoinCheck = (sessionStorage.getItem("isRokidAstJoin") === "true") ?? false;
    let destinationNode;
    let recording_canvas = document.querySelector("#recording_canvas");
    var stream = recording_canvas.captureStream(30 /*fps*/);

    if (isConferenceCall) {
      const audioContext = new AudioContext();
      destinationNode = audioContext.createMediaStreamDestination();
      const localAudioFeed = await getLocalMicStream();
      const audioStream1 = localAudioFeed;
      audioStream1.addTrack(localAudioFeed.getAudioTracks()[0]);

      const sourceNode1 = audioContext.createMediaStreamSource(audioStream1);
      sourceNode1.connect(destinationNode);

      if (isWebAstJoinCheck) {
        const audioStream2 = remoteAudioRef1.current.srcObject;
        const sourceNode2 = audioContext.createMediaStreamSource(audioStream2);
        sourceNode2.connect(destinationNode);
      }

      if (isRokidAstJoinCheck) {
        const audioStream3 = remoteAudioRef2.current.srcObject;
        const sourceNode3 = audioContext.createMediaStreamSource(audioStream3);
        sourceNode3.connect(destinationNode);
      }

    } else {
      const audioStream1 = new MediaStream([localAudioTrack]);
      const audioStream2 = new MediaStream([remoteAudioTrack]);
      // create an audio context and a media stream destination node
      const audioContext = new AudioContext();
      // create an audio source node for each audio track and connect it to the destination node
      destinationNode = audioContext.createMediaStreamDestination();
      const sourceNode1 = audioContext.createMediaStreamSource(audioStream1);
      const sourceNode2 = audioContext.createMediaStreamSource(audioStream2);
      sourceNode1.connect(destinationNode);
      sourceNode2.connect(destinationNode);
    }

    let audioTracks = destinationNode.stream.getAudioTracks()[0];
    // create one video and audio stream
    const newStream = new MediaStream();
    newStream.addTrack(audioTracks);
    newStream.addTrack(stream.getVideoTracks()[0]);

    // create a new MediaRecorder
    mediaRecorder = new MediaRecorder(newStream);

    // create an array to store the video chunks
    mediaRecorder.ondataavailable = (event) => {
      chunks.push(event.data);
    };

    if (isMainView && isRokidAstJoin) {
      socketRef.current?.emit(WAST_MAIN_VIEW, sessionIdRef.current);
    } else if (!isMainView && isWebAstJoin) {
      socketRef.current?.emit(ROKID_MAIN_VIEW, sessionIdRef.current);
    }

    mediaRecorder.start();
    socketRef.current?.emit(RECORDING_STARTED, { sessionId: sessionIdRef.current, webAssistantFlag });
    setRecording(true);
    sessionStorage.setItem("isRecording", JSON.stringify(true));
    setRecordingSession("true");
  };

  const handleStopRecording = () => {
    let webAssistantFlag = (isMainView && isWebAstJoin) ? 1 : 0;
    const recordingStarted = sessionStorage.getItem("isRecording") === "true";
    if (mediaRecorder != undefined && recordingStarted) {
      if (mediaRecorder?.state === "recording") {
        mediaRecorder.stop();
        socketRef.current?.emit(RECORDING_ENDED, webAssistantFlag);
        toast.info(t('lbl_recordingStopSaving'));
      }
      setRecording(false);
      sessionStorage.setItem("isRecording", JSON.stringify(false));
      mediaRecorder.onstop = async function (event) {
        var videoBlob = new Blob(chunks, { type: "video/webm" });
        let recordingURL = URL.createObjectURL(videoBlob);
        var link$ = document.createElement("a");
        link$.href = recordingURL;
        link$.download = `call-with-${state?.assistantName}-recording`;
        link$.click();
      };
      chunks = [];
    }
  };

  // draw glass stream draw on canvas for recording and snapshot
  const onPlayHandler = (e) => {
    let canvas = document.querySelector("#canvas");
    let recording_canvas = document.querySelector("#recording_canvas");
    let ctxs = recording_canvas.getContext("2d");
    (function loop() {
      // Calculate zoomed dimensions based on the canvas dimensions
      let zoomedWidth = 920 * (zoomLevel / 100);
      let zoomedHeight = 520 * (zoomLevel / 100);
      let xOffset = (920 - zoomedWidth) / 2; // Calculate the offset to center horizontally
      let yOffset = (520 - zoomedHeight) / 2; // Calculate the offset to center vertically

      if (isConferenceCall) {
        if (recordMainView && isWebAstJoin) {
          if (!remoteVideoRef1.current) return;
          ctxs.drawImage(remoteVideoRef1.current, xOffset, yOffset, zoomedWidth, zoomedHeight);
        } else if (!recordMainView && isRokidAstJoin) {
          if (!remoteVideoRef2.current) return;
          ctxs.drawImage(remoteVideoRef2.current, xOffset, yOffset, zoomedWidth, zoomedHeight);
        }
      } else {
        if (!remoteVideoRef1.current) return;
        ctxs.drawImage(remoteVideoRef1.current, xOffset, yOffset, zoomedWidth, zoomedHeight);
      }
      // Draw the canvas onto the recording canvas
      ctxs.drawImage(canvas, 0, 0, 920, 520);
      setTimeout(loop, 1000 / 120);
    })();
  };

  //consultant audio from getLocalMicStream using getUserMedia.
  //clear mediaStream on end call.
  const stopMediaStream = () => {
    if (consAudio) {
      consAudio.getTracks().forEach(track => track.stop());
      setConsAudio(null);
    }
  };

  useEffect(() => {
    window.onbeforeunload = () => {
      if (mediaRecorder?.state === "recording") {
        let webAssistantFlag = (isMainView && isWebAstJoin) ? 1 : 0;
        handleStopRecording();
        socketRef.current?.emit(RECORDING_ENDED, webAssistantFlag);
        setRecording(false);
      }
      if (window.location.pathname.includes("/call")) {
        dataConsumption(dataSent, false);
      }
    }
  }, [])

  const handleEndCall = async () => {
    clearInterval(intervalId);
    const recordingStarted = sessionStorage.getItem("isRecording") === "true";
    isRunning = sessionStorage.getItem("isRunning");
    const isRunningString = isRunning === "true";
    if (mediaRecorder?.state === "recording" && recordingStarted) {
      handleStopRecording();
    }
    if (isRunningString) {
      dataConsumption(dataSent, true);
    }
    sfutest?.hangup();
    await janus?.destroy();
    const assistantName = sessionStorage.getItem("assistantUserName");
    socketRef.current?.emit(END_CALL, { sessionData: sessionIdRef.current, assistantName, callEndUserRole: 3 });
    navigate("/callquality");
    handleReset();
    setIsConferenceCall(false)
    setEmailAddress('');
    setPhoneNumber('');
    sessionStorage.setItem("isConferenceCall", JSON.stringify(false));
    sessionStorage.setItem("isRunning", JSON.stringify(true));
    sessionStorage.removeItem("isWebAssistant");
    sessionStorage.removeItem("assistantUserName");
    sessionStorage.removeItem("rokidWidth");
    sessionStorage.removeItem("rokidHeight");
    sessionStorage.removeItem("isWebAssistantJoin");
    sessionStorage.removeItem("isRokidAstJoin");
    sessionStorage.removeItem("wastSelfMuted");
    SetIsMuteRokid(false);
    SetIsWebAsstMuted(false);
    setWastInviteSent(false);
    setRokidInviteDialog(false);
    isRokidOnMain = true;
    setRokidInviteSent(false);
    recordMainView = true;
    stopMediaStream();
    clearInterval(webassistantIntervalId);
    clearInterval(rokidIntervalId);
    isWAConferenceCallRef.current = false;
  };

  // DRAW RECTANGLE HANDLER - completed
  const drawRectangle = (active) => {
    if (canvas.getObjects().filter((obj) => obj.type === "rect").length > 0) return; else setSelectedIcon(active);
    if (canvas.isDrawingMode) canvas.isDrawingMode = false;
    drawnRect = false;
    drawnCircle = true;
    if (drawnCircle) {
      canvas.off("mouse:down");
      canvas.off("mouse:move");
      canvas.off("mouse:up");
    }
    if (!drawnRect) {
      setIsButtonSelected('rect');
      var rect, isDown, origX, origY, rectDrawn;
      var x = 0;
      var y = 0;

      // mouse down
      canvas.on("mouse:down", function (o) {
        arr_coords = [];
        if (
          canvas.getObjects().filter((obj) => obj.type === "rect").length > 0
        ) {
          return;
        } else {
          isDown = true;
          var pointer = canvas.getPointer(o.e);
          origX = pointer.x;
          origY = pointer.y;
          rect = new fabric.Rect({
            left: origX,
            top: origY,
            fill: "transparent",
            width: 20,
            height: 20,
            strokeWidth: 2,
            stroke: "red",
            hasControls: true,
            hasRotatingPoint: false, // Disable rotating controls
            strokeUniform: true // Ensure stroke width remains constant
          });
          canvas.add(rect);
          // Hide rotating control
          rect.setControlVisible('mtr', false)
        }
      });

      //mouse move event on canvas
      canvas.on("mouse:move", function (o) {
        if (!isDown) return;
        var pointer = canvas.getPointer(o.e);
        var width = Math.abs(pointer.x - origX);
        var height = Math.abs(pointer.y - origY);
        var newLeft = origX > pointer.x ? pointer.x : origX;
        var newTop = origY > pointer.y ? pointer.y : origY;
        rect.set({
          left: newLeft,
          top: newTop,
          width: width,
          height: height
        });
        canvas.renderAll();
      });

      // mouse up
      canvas.on("mouse:up", function (o) {
        isDown = false;
        drawnRect = true;
        let activeRect = canvas.getActiveObject();
        let acoords = activeRect?.aCoords;
        const isWebAssistant = sessionStorage.getItem("isWebAssistant") === "true";
        const isConferenceCall = sessionStorage.getItem("isConferenceCall") === "true";
        let webAssistantViewFlag = (isWebAssistant && isConferenceCall) ? 1 : 0;
        if (!acoords) return;
        rect.setCoords();// Update coordinates of the circle
        canvas.setActiveObject(rect); // Set the circle as the active object
        canvas.renderAll();
        canvas.fire('object:modified', { target: rect });
        canvas.off("mouse:down");
        canvas.off("mouse:move");
        canvas.off("mouse:up");
        setSelectedIcon('');
        setIsButtonSelected('');
      });
    }
    return;
  };

  // DRAW CIRCLE HANDLER
  const handledrawCircle = (active) => {
    sessionStorage.setItem("userTyping", "false")
    if (canvas.getObjects().filter((obj) => obj.type === "circle").length > 0) return; else setSelectedIcon(active);
    drawnCircle = false;
    drawnRect = true;
    if (canvas.isDrawingMode) canvas.isDrawingMode = false;
    if (drawnRect) {
      canvas.off("mouse:down");
      canvas.off("mouse:move");
      canvas.off("mouse:up");
    }
    var circle, isDown, origX, origY, circleDrawn;
    if (!drawnCircle) {
      setIsButtonSelected('circle');
      canvas.on("mouse:down", function (o) {
        arr_coords = [];
        if (canvas.getObjects().filter((obj) => obj.type === "circle").length > 0) {
          return;
        } else {
          isDown = true;
          var pointer = canvas.getPointer(o.e);
          origX = pointer.x;
          origY = pointer.y;
          circle = new fabric.Circle({
            left: pointer.x,
            top: pointer.y,
            radius: 10,
            strokeWidth: 2,
            fill: "transparent",
            stroke: "red",
            selectable: true,
            originX: "center",
            originY: "center",
            hasControls: true,
            hasRotatingPoint: false, // Disable rotating controls
            strokeUniform: true // Ensure stroke width remains constant
          });
          canvas.add(circle);
          // Hide rotating control
          circle.setControlVisible('mtr', false)
        }
      });

      if (!circleDrawn) {
        canvas.on("mouse:move", function (o) {
          if (!isDown) return;
          var pointer = canvas.getPointer(o.e);
          circle.set({ radius: Math.abs(origX - pointer.x) });
          canvas.renderAll();
        });

        if (isDown) return;
        canvas.on("mouse:up", function (o) {
          isDown = false;
          drawnCircle = true;
          let activeCircle = canvas.getActiveObject();
          let acoords = activeCircle?.aCoords;
          const isWebAssistant = sessionStorage.getItem("isWebAssistant") === "true";
          const isConferenceCall = sessionStorage.getItem("isConferenceCall") === "true";
          let webAssistantViewFlag = (isWebAssistant && isConferenceCall) ? 1 : 0;
          if (!acoords) return;
          circle.setCoords();// Update coordinates of the circle
          canvas.setActiveObject(circle); // Set the circle as the active object               
          canvas.renderAll();
          canvas.fire('object:modified', { target: circle });
          canvas.off("mouse:down");
          canvas.off("mouse:move");
          canvas.off("mouse:up");
          setSelectedIcon('');
          setIsButtonSelected('');
          return;
        });
      }
    }
  };

  // DRAW FREE HAND
  const handleDrawFreeHand = (active) => {
    setIsButtonSelected('FreeHand');
    setSelectedIcon(active);
    drawnRect = true;
    drawnCircle = true;
    canvas.off("mouse:down");
    canvas.off("mouse:move");
    canvas.off("mouse:up");
    if (canvas.isDrawingMode) {
      canvas.isDrawingMode = false;
      canvas.forEachObject(function (obj) {
        obj.set({
          selectable: false,
          evented: false
        });
      });
    } else {
      // Enable drawing mode
      canvas.isDrawingMode = true;
      canvas.freeDrawingBrush.width = 5;
      canvas.freeDrawingBrush.color = "#00aeff";
      canvas.on("mouse:down", function ({ e }) {
        isDrawing = true;
        arr_coords = [];
        onMouseDown(e);
      })
        .on("mouse:up", function ({ e }) {
          isDrawing = false;
          onMouseUp(e);
          setSelectedIcon('');
          canvas.getObjects().forEach(function (obj) {
            if (obj.type === 'path') {
              obj.set({
                selectable: false,
                evented: false,
                lockScalingX: true,
                lockScalingY: true,
                lockMovementX: true,
                lockMovementY: true,
                lockRotation: true
              });
            }
          });
          canvas.off("mouse:down");
          canvas.off("mouse:move");
          canvas.off("mouse:up");
          setIsButtonSelected('');
        })
        .on("mouse:move", function ({ e }) {
          if (isDrawing) {
            const pointer = canvas.getPointer(e);
            drawRealTime(e, pointer);
          }
        });
    }
  };

  // CLEAR ALL ANNOTATION
  const handleClearAnnotation = () => {
    const isWebAssistant = sessionStorage.getItem("isWebAssistant") === "true";
    const isConferenceCall = sessionStorage.getItem("isConferenceCall") === "true";
    let webAssistantViewFlag = (isWebAssistant && isConferenceCall) ? 1 : 0;
    socketRef.current?.emit(CLEAR_SCREEN, webAssistantViewFlag, sessionIdRef.current);
    socketRef.current?.emit(REMOVE_TEXT, webAssistantViewFlag, sessionIdRef.current);
    canvas?.clear();
    setNotes('');
    setTakeNotes(false);
    setSelectedIcon('');
    arr_coords = [];
    setIsButtonSelected('');
    sessionStorage.setItem("userTyping", "false")
  };

  // SNAPSHOT DOWNLOAD HANDLER 
  const snapshotHandler = async () => {
    let canvas = document.querySelector("#canvas");
    let snapshot = document.querySelector("#snapshot_canvas");
    let ctxs = snapshot.getContext("2d");

    if (isConferenceCall) {
      if (!isWebAstJoin && !isRokidAstJoin)
        return toast.info(t('lbl_patientIsNotAvailable'));

      if (isMainView) {
        if (!isWebAstJoin && isRokidAstJoin) {
          return toast.info(t('lbl_patientIsNotAvailable'))
        } else if (isWebAstJoin && (!isRokidAstJoin || isRokidAstJoin)) {
          ctxs.drawImage(remoteVideoRef1.current, 0, 0, 920, 520);
        }
      } else {
        if (isRokidAstJoin && (isWebAstJoin || !isWebAstJoin)) {
          ctxs.drawImage(remoteVideoRef2.current, 0, 0, 920, 520);
        } else {
          return toast.info(t('lbl_smartGlassAssistantIsNotAvailable'));
        }
      }
    }
    else
      ctxs.drawImage(remoteVideoRef1.current, 0, 0, 920, 520);

    ctxs.drawImage(canvas, 0, 0, 920, 520);
    var dataURL = snapshot.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = "screenshot.png";
    link.click();
    toast.success(t('lbl_pictureHasTaken'))
  };

  // DELETE CANVAS OBJECT ON delete key
  window.onkeydown = deleteObject;
  function deleteObject(e) {
    const isWebAssistant = sessionStorage.getItem("isWebAssistant") === "true";
    const isConferenceCall = sessionStorage.getItem("isConferenceCall") === "true";
    let webAssistantViewFlag = (isWebAssistant && isConferenceCall) ? 1 : 0;
    if (e.keyCode === 46) {
      let object = canvas.getActiveObject()?.get("type");
      socketRef.current?.emit(REMOVE_OBJECT, {
        type: object,
        sessionId: sessionIdRef.current,
        webAssistantViewFlag
      });
      arr_coords = [];
      canvas.remove(canvas.getActiveObject());
      return;
    }
    return;
  };

  const SendTextAnnotation = () => {
    const isWebAssistant = sessionStorage.getItem("isWebAssistant") === "true";
    const isConferenceCall = sessionStorage.getItem("isConferenceCall") === "true";
    let webAssistantViewFlag = (isWebAssistant && isConferenceCall) ? 1 : 0;
    canvas.remove(canvasTxtRef.current);
    canvasTxtRef.current = new fabric.Textbox(notes, {
      left: canvas.width / 10,
      top: canvas.height / 1.2,
      width: (canvas.width / 1.25),
      fill: '#ffffff',
      fontSize: 20,
      splitByGrapheme: true
    });
    canvas.add(canvasTxtRef.current); const adjustCanvasScale = () => {
      const scaleFactor = Math.min(canvas.width / canvas.getWidth(), canvas.height / canvas.getHeight());
      canvas.setZoom(scaleFactor);
    };
    adjustCanvasScale();
    setTimeout(() => {
      sessionStorage.setItem("userTyping", "false")
    }, 1000);
    socketRef.current?.emit(DRAW_TEXT, { notes: notes, sessionId: sessionIdRef.current, webAssistantViewFlag });
    if (notes && !isSend) setIsSend(true);
  }

  const cancelSendTextAnnotations = () => {
    const isWebAssistant = sessionStorage.getItem("isWebAssistant") === "true";
    const isConferenceCall = sessionStorage.getItem("isConferenceCall") === "true";
    let webAssistantViewFlag = (isWebAssistant && isConferenceCall) ? 1 : 0;
    socketRef.current?.emit(REMOVE_TEXT, webAssistantViewFlag);
    canvas.remove(canvasTxtRef.current);
    setNotes('');
    setTakeNotes(false);
    setSelectedIcon('');
    sessionStorage.removeItem("userTyping");
    setTextAnnotationError(false);
  }

  const handleSendTextAnnotation = (isSend, notes) => {
    if (isSend && notes) {
      cancelSendTextAnnotations()
    } else {
      SendTextAnnotation()
    }
  }

  const Send = () => (
    <IconButton disableRipple onClick={() => { handleSendTextAnnotation(isSend, notes); }} >
      {isSend && notes ? <CancelOutlinedIcon color='error' onClick={() => { handleSendTextAnnotation(isSend, notes) }} />
        : <SendOutlinedIcon color='primary' onClick={() => { handleSendTextAnnotation(isSend, notes) }} />}
    </IconButton >
  );

  // completed
  const zoomIn = () => {
    if (zoomLevel < 200 && zoomLevel >= 100) {
      zoomLevel += 20;
      let id = isMainView ? "video1" : "video3";
      const video = document.getElementById(id);
      canvas.zoomToPoint(
        new fabric.Point(canvas.width / 2, canvas.height / 2),
        zoomLevel / 100
      );
      video.style.transform = `scale(${zoomLevel / 100})`;
    }
  };

  // completed
  const zoomOut = () => {
    if (zoomLevel > 100 && zoomLevel <= 200) {
      zoomLevel -= 20;
      let id = isMainView ? "video1" : "video3";
      const video = document.getElementById(id);
      canvas.zoomToPoint(
        new fabric.Point(canvas.width / 2, canvas.height / 2),
        zoomLevel / 100
      );
      video.style.transform = `scale(${zoomLevel / 100})`;
    }
  };

  const handleStayInCall = () => {
    setUserLeftAlert(false);
    setNavigationBackPopup(false);
    setIsRemoteVideo(true);
    setRecordingPopup(false);
  }

  const inviteAssistant = () => {
    setInviteDialog(true)
    setSelfEmailAddress(user?.userEmail)
  }

  const inviteRokidAssistant = () => {
    setRokidInviteDialog(true)
  }

  const handleMuteWhileRecording = () => {
    if (isConferenceCall) {
      if (!isMainView && recording && isWebAstJoin) {
        socketRef.current?.emit(WAST_MAIN_VIEW, sessionIdRef.current);
      } else if (isMainView && recording && isRokidAstJoin) {
        socketRef.current?.emit(ROKID_MAIN_VIEW, sessionIdRef.current);
      }
    }
  }

  const switchVideoContainers = () => {
    setShowMuteIcon(!showMuteIcon);
    isRokidOnMainInConf = !isRokidOnMainInConf;
    const isWebAssistant = sessionStorage.getItem("isWebAssistant") === "true";
    const isConferenceCall = sessionStorage.getItem("isConferenceCall") === "true";
    let webAssistantViewFlag = (isWebAssistant && isConferenceCall) ? 1 : 0;
    const videoElement1 = remoteVideoRef1.current;
    const videoElement2 = remoteVideoRef2.current;
    if (videoElement1 || videoElement2) {
      const newContainer1 = container2;
      const newContainer2 = container1;
      const currentContainer1 = document.getElementById(container1);
      const currentContainer2 = document.getElementById(container2);
      if (currentContainer1 && currentContainer2) {
        currentContainer1.appendChild(videoElement2);
        currentContainer2.appendChild(videoElement1);
      }
      if (videoElement1) { //Show annotate btn if rokid joined only
        setShowAnnotateBtn(!showAnnotateBtn);
        setShowMuteIcon(!showMuteIcon)
      }
      setContainer1(newContainer1);
      setContainer2(newContainer2);
      setIsMainView(!isMainView);
    }
    const keyExists = sessionStorage.getItem('isWebAssistant') !== null;
    if (keyExists) {
      if (sessionStorage.getItem("isWebAssistant") === "true") {
        sessionStorage.setItem("isWebAssistant", JSON.stringify(false));
      } else {
        sessionStorage.setItem("isWebAssistant", JSON.stringify(true));
      }
    }
    else {
      sessionStorage.setItem("isWebAssistant", JSON.stringify(false));
    }

    handleMuteWhileRecording();
    recordMainView = (!recordMainView);
    toggleMutedStates();
    canvas?.clear();
    setNotes('');
    setTakeNotes(false);
    setSelectedIcon('');
    setAnnotate(false);
    socketRef.current?.emit(CLEAR_SCREEN, webAssistantViewFlag, sessionIdRef.current);
    handleConsultantAudioTransmit();
    maintainAspectRatio();
    handleResizeCanvas();
  };

  useEffect(() => {
    const storedIsConferenceCall = sessionStorage.getItem('isConferenceCall');
    if (storedIsConferenceCall) {
      setIsConferenceCall(JSON.parse(storedIsConferenceCall));
    }
  }, []);

  function toggleMutedState(audioId) {
    const audioElement = document.getElementById(audioId);
    if (audioElement) {
      audioElement.muted = !audioElement.muted;
    }
  }

  function toggleMutedStates() {
    toggleMutedState("audio1");
    toggleMutedState("audio2");
  }

  // by Deepika

  // setting up - outer container height , canvas dimensions on resize, and video parent container dimensions according device width by maintaining aspectRatio
  const outerContainerRef = useRef(null);
  const viewportHeightRef = useRef(document.documentElement.clientHeight);

  const initResizeOuterContainer = () => {
    setTimeout(() => {
      viewportHeightRef.current = document.documentElement.clientHeight;
      outerContainerRef.current.style.height = `${viewportHeightRef.current}px`;
      maintainAspectRatio();
      handleResizeCanvas();
    }, 10);
  };
  const handleResizeOuterContainer = () => {
    viewportHeightRef.current = document.documentElement.clientHeight;
    outerContainerRef.current.style.height = `${viewportHeightRef.current}px`;
    maintainAspectRatio();
    handleResizeCanvas();
  };
  useEffect(() => {
    initResizeOuterContainer();
    window.addEventListener('resize', handleResizeOuterContainer);
    return () => {
      window.removeEventListener('resize', handleResizeOuterContainer);
    };
  }, []);

  // by Deepika ENDs here

  //Emit the ON_OFF_AUDIO event to handle consultant audio based on which assistant is on the main view.
  const handleConsultantAudioTransmit = () => {
    const isWebassistantView = (sessionStorage.getItem("isWebAssistant") === "true") ?? false;
    const isWebAstJoin = (sessionStorage.getItem("isWebAssistant") === "true") ?? false;
    const isRokidAstJoin = (sessionStorage.getItem("isRokidAstJoin") === "true") ?? false;
    let webAssistantViewFlag = (isWebassistantView && isConferenceCall) ? 1 : 0;
    const keyExists = sessionStorage.getItem('isWebAssistant') !== null;

    if (isWebassistantView && isWebAstJoin) { // WAST(main view) & WAST join & ROKID not Join 
      socketRef.current?.emit(ON_OFF_AUDIO, { sessionId: sessionIdRef.current, webAssistantViewFlag });
    } else if ((!isWebassistantView && isWebAstJoin) || (!isWebassistantView && keyExists)) { // WAST (Remote View) & WAST join & ROKID not join
      socketRef.current?.emit(ON_OFF_AUDIO, { sessionId: sessionIdRef.current, webAssistantViewFlag });
    } else if (isWebassistantView && isRokidAstJoin && !isWebAstJoin) { // WAST (main view) & WAST not join & Rokid join
      socketRef.current?.emit(ON_OFF_AUDIO, { sessionId: sessionIdRef.current, webAssistantViewFlag });
    } else if (!isWebassistantView && isRokidAstJoin && !isWebAstJoin) { // WAST(Remote view) & WAST not join & Rokid join
      socketRef.current?.emit(ON_OFF_AUDIO, { sessionId: sessionIdRef.current, webAssistantViewFlag: 1 });
    } else if (isWebassistantView && isRokidAstJoin && isWebAstJoin) { // WAST(main view) & WAST join & Rokid join
      socketRef.current?.emit(ON_OFF_AUDIO, { sessionId: sessionIdRef.current, webAssistantViewFlag });
    } else if (!isWebassistantView && isRokidAstJoin && isWebAstJoin) { // WAST(Remote view) & WAST join & Rokid join
      socketRef.current?.emit(ON_OFF_AUDIO, { sessionId: sessionIdRef.current, webAssistantViewFlag });
    }
  }

  const dataConsumption = async (dataSent, isEndCall) => {

    const conferenceCallFlag = sessionStorage.getItem("isConferenceCall") === "true";
    const isWebAstJoinCheck = (sessionStorage.getItem("isWebAssistantJoin") === "true") ?? false;
    const isRokidAstJoinCheck = (sessionStorage.getItem("isRokidAstJoin") === "true") ?? false;
    const roleId = sessionStorage.getItem("role")
    const subscriptionId = sessionStorage.getItem("subscriptionId")

    const consultantIntervalData = dataSent - conIntervalDataSent;
    const webassistantIntervalData = wastDataReceived - wastIntervalDataSent;
    const rokidIntervalData = rokidDataReceived - rokidIntervalDataSent;

    let finalValue;
    if (isWebAstJoinCheck && isRokidAstJoinCheck) {
      finalValue = webassistantIntervalData + rokidIntervalData;
    } else if (isWebAstJoinCheck) {
      finalValue = webassistantIntervalData;
    } else if (isRokidAstJoinCheck) {
      finalValue = rokidIntervalData;
    } else {
      finalValue = 0;
    }

    if (!conferenceCallFlag) {
      finalValue = rokidIntervalData;
    }

    const params = {
      sessionId: sessionIdRef.current,
      subscriptionId: JSON.parse(subscriptionId),
      userId: user.userId,
      roleId: JSON.parse(roleId),
      dataSent: parseFloat(consultantIntervalData.toFixed(2)),
      dataReceived: parseFloat(finalValue.toFixed(2)),
      isConferenceCall: conferenceCallFlag,
      isEndCall
    };

    try {
      const resp = await apis.instance.post(`${SubscriptionDataUsage}`, params);
      if (resp.data.success) {
        conIntervalDataSent = dataSent;
        wastIntervalDataSent = wastDataReceived;
        rokidIntervalDataSent = rokidDataReceived;
        if (resp.data.data.remainingDataInPercentage <= process.env.REACT_APP_DATA_LIMIT) {
          setShowDataText(true);
          setRemainingDataPercentage(resp.data.data.remainingDataInPercentage);
          setDataExhausted(true);
        }
        if (resp.data.data.remainingData == 0) {
          sessionStorage.setItem("isRunning", JSON.stringify(false));
          handleEndCall();
        }
      } else {
        const errorMessage = resp.data.error ? t(resp.data.error) : t('responseMsg.failure');
        toast.error(errorMessage);
      }
    } catch (error) {
      const errorMessage = error.response && error.response.data && error.response.data.message ?
        t(error.response.data.message) :
        t('responseMsg.error');
      toast.error(errorMessage);
    }
  };

  const handleDataExhaustedPopup = () => {
    setShowDataExhausted(false);
    setDataExhausted(false);
  }
  const handleDelete = () => {
    setShowDataText(false);
  };
  return (
    <>

      <DataExhaustedDialog
        isOpen={(showDataExhausted && dataExhausted)}
        handleDataExhaustedPopup={handleDataExhaustedPopup}
        dataExhausted={dataExhausted}
      />

      {navigationBackPopup &&
        <Dialog
          open={navigationBackPopup}
          onClose={() => { return null }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box backgroundColor={'rgba(233, 57, 79, 0.2)'} color={'rgba(233, 57, 79, 1)'} padding={'8px 20px'}>
            <Typography variant='body2' fontWeight={'normal'}><Typography display={'inline'} fontWeight={'bold'} variant='body2'>Note:&nbsp;</Typography>{t('lbl_confirmLeaveCallWarning')}</Typography>
          </Box>
          <DialogTitle id="alert-dialog-title">
            {t('lbl_confirmation')}
          </DialogTitle>
          <DialogContent>
            {t('lbl_endCall')}
          </DialogContent>
          <DialogActions>
            <CadisButton variant="contained" color="error" onClick={handleEndCall}>{t('lbl_yes')}</CadisButton>
            <CadisButton variant="contained" color="success" onClick={handleStayInCall}>{t('lbl_no')}</CadisButton>
          </DialogActions>
        </Dialog>
      }

      {userLeftAlert &&
        <CadisDialog open={userLeftAlert} title={`${state?.assistantName} left. You want to Stay in call ?`} DialogActions={
          <>
            <Button variant="contained" color="error" onClick={handleEndCall}>
              End Call
            </Button>
            <Button variant="contained" color='success' onClick={handleStayInCall}>
              Stay
            </Button>
          </>
        } />}
      {openInviteDialog()}
      {openReconnectDialog()}
      {openRokidUserInviteDialog()}
      <Box pl={2} pr={2} boxSizing="border-box" backgroundColor="#222222" className="consultant-call-screen" display="block" width="100vw" ref={outerContainerRef}>
        <Box display="flex" flexDirection="column" height="100%" width="100%" justifyContent={"center"} className="call_container" boxSizing="border-box">
          <>
            <Box sx={{ minHeight: "40px", height: "40px", display: "flex", alignItems: "center", color: "#ffffff" }}>
              <Grid container direction={"row"} spacing={0} border={'solid 0 yellow'} width={'100%'} height={'100%'} margin={'0'} alignItems={'center'}>
                <Grid item xs>
                  <Box display={'flex'}>
                    <Typography variant="h5" visibility={isRemoteVideo ? 'visible' : 'hidden'} sx={{ maxWidth: 'calc( 96% - 20px)', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {isMainView && isConferenceCall && (
                        <>{t('lbl_patientView')}</>
                      )}
                      {!isMainView && isConferenceCall && (
                        <>{t('lbl_smartGlassAssistantView')}</>
                      )}
                      {!isConferenceCall && (
                        <>{state?.assistantName}</>
                      )}
                    </Typography>
                    {(isMuteRokid && showMuteIcon) || (isWebAsstMuted && !showMuteIcon) || (isMuteRokid && !isConferenceCall) ?
                      <MicOffIcon color="error" sx={{ paddingLeft: 1 }} /> : null
                    }
                  </Box>
                </Grid>
                <Grid item xs={'auto'}>
                  {!isRemoteVideo || (failedPong > 2) ? (
                    <Typography>{t('lbl_connecting')}...</Typography>
                  ) : null}
                  {/* commented backup code */}
                  {/* {showDataText &&
                    <Box color={'rgba(233, 57, 79, 1)'} padding={'2px 10px'}>
                      <Typography variant='body2' fontWeight={'normal'}>
                        {t('lbl_dataLimitOnly')} {remainingDataPercentage}% {t('lbl_dataLimitOnlyContinuousText')}
                      </Typography>
                    </Box>
                  } */}
                </Grid>
                <Grid item xs textAlign={"right"}>
                  <Typography variant="h5" fontSize={"large"} component={"h6"} sx={{ color: "#F2AF4C" }} >
                    {hours < 10 ? "0" + hours : hours}:{minutes < 10 ? "0" + minutes : minutes}:
                    {seconds < 10 ? "0" + seconds : seconds}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box display={"flex"} justifyContent={"center"} position={"relative"} height={{ xs: 'calc(100% - 134px)', sm: 'calc(100% - 108px)', md: 'calc(100% - 84px)' }}>
              <Grid container direction={"row"} spacing={1} border={'solid 0 yellow'} width={'100%'} height={'100%'} margin={'0'} flexWrap={'nowrap'}>
                <Grid item xs p={'0 !important'} display={"flex"} justifyContent={"center"} border={'solid 0 white'} maxWidth={isConferenceCall ? 'calc(100% - 210px) !important' : '100%'}>
                  <div className="video_container primary-video-container" ref={containerRef}>
                    {showDataText && <Box sx={{ position: "absolute", top: "5px", zIndex: "2" }}>
                      <Chip label={`${t('lbl_dataLimitOnly')} ${remainingDataPercentage}% ${t('lbl_dataLimitOnlyContinuousText')}`} onDelete={handleDelete} color="info" size="medium" />
                    </Box>}
                    {takeNotes && (
                      <>
                        {/* <Draggable defaultPosition={{ x: 0, y: 0 }} bounds="parent"> */}
                        <div className="notes">
                          <TextField
                            onChange={(e) => {
                              const spliceText = e.target.value.slice(0, 300)
                              setNotes(spliceText);
                              if ((e.target.value.length) > 300) {
                                setTextAnnotationError(true);
                                return
                              } else {
                                setIsSend(false);
                                setTextAnnotationError(false);
                              }
                            }}
                            onBlur={() => {
                              sessionStorage.setItem("userTyping", "true")
                            }}
                            onKeyDown={(e) => {
                              if ((e.target.value.length) > 300) {
                                setTextAnnotationError(true);
                                return
                              } else {
                                setTextAnnotationError(false);
                                if (e.key === 'Enter') {
                                  const isWebAssistant = sessionStorage.getItem("isWebAssistant") === "true";
                                  const isConferenceCall = sessionStorage.getItem("isConferenceCall") === "true";
                                  let webAssistantViewFlag = (isWebAssistant && isConferenceCall) ? 1 : 0;

                                  canvas.remove(canvasTxtRef.current);
                                  socketRef.current?.emit(DRAW_TEXT, { notes: e.target.value, webAssistantViewFlag });
                                  canvasTxtRef.current = new fabric.Textbox(notes, {
                                    left: canvas.width / 10,
                                    top: canvas.height / 1.2,
                                    width: (canvas.width / 1.25),
                                    fill: '#ffffff',
                                    fontSize: 20,
                                    splitByGrapheme: true
                                  });
                                  canvas.add(canvasTxtRef.current);
                                  const adjustCanvasScale = () => {
                                    const scaleFactor = Math.min(canvas.width / canvas.getWidth(), canvas.height / canvas.getHeight());
                                    canvas.setZoom(scaleFactor);
                                  };
                                  adjustCanvasScale();
                                  if (notes && !isSend) setIsSend(true);
                                  document.getElementById("messageInput").blur()
                                  sessionStorage.setItem("userTyping", "textSend")
                                  // setTakeNotes(false)
                                  setTimeout(() => {
                                    sessionStorage.setItem("userTyping", "false")
                                  }, 1000);
                                }
                              }
                            }}
                            id="messageInput"
                            size="small"
                            name="username"
                            type="text"
                            value={notes}
                            placeholder={`${t('lbl_writeNotesHere')}...`}
                            autoComplete="off"
                            InputProps={{ endAdornment: <Send /> }}
                            error={textAnnotationError}
                            helperText={textAnnotationError ? t("validation.msg_sendTextAnnotation") : ""}
                            sx={{
                              backgroundColor: "rgb(52, 52, 52, 0.3)",
                              input: { color: "#ffffff" },
                            }}
                          />
                        </div>
                        {/* </Draggable> */}
                      </>
                    )}
                    <audio id="audio1" ref={remoteAudioRef1} muted={false} autoPlay={true} />
                    <audio id="audio2" ref={remoteAudioRef2} muted={true} autoPlay={true} />
                    <div id="container1" className="primary-video" ref={innerContainerRef}>
                      <video
                        id="video1"
                        ref={remoteVideoRef1}
                        muted={false}
                        playsInline
                        autoPlay
                        className="glass_video"
                        onPlay={onPlayHandler}
                        sx={{ border: 'solid 0px blue', boxSizing: 'border-box' }}
                      />
                      <canvas id="canvas" sx={{ position: "absolute", width: '1322px !important', height: '100%' }} />
                    </div>
                  </div>
                </Grid>
                {isConferenceCall ? (
                  <Grid item xs={'auto'} p={'0 !important'} width={'210px !important'} boxSizing={'border-box'} display={"flex"} justifyContent={"space-between"} alignItems={"flex-end"} flexDirection={'column'}>
                    <Box sx={{ textAlign: 'right' }}>
                     { !isWAConferenceCallRef.current && <Button id="InviteWebBtn" onClick={inviteAssistant} variant="contained" color={wastInviteSent ? 'inherit' : 'info'} sx={{ mb: 1, pointerEvents: wastInviteSent ? 'none' : 'auto' }} >
                        {t('lbl_invitePeopleJoin')}
                      </Button>}
                      <Button id="InviteRokidBtn" onClick={inviteRokidAssistant} variant="contained" color={rokidInviteSent ? 'inherit' : 'success'} sx={{ mb: 1, ml: 1, pointerEvents: rokidInviteSent ? 'none' : 'auto', textAlign: 'left', display: 'inline-block', maxWidth: '159px' }} >
                        {t('lbl_inviteSmartGlassJoin')}
                      </Button>
                    </Box>
                    <Box display={"flex"} flexDirection={"column-reverse"} overflow={'hidden auto'} >
                      {/* self view box */}
                      <Box sx={{ aspectRatio: '16/9', width: '200px', borderRadius: "10px", position: 'relative', border: 'solid 1px rgba(255,255,255,0.16)', margin: '7px 0 0 7px', boxSizing: 'border-box' }} className="secondary-video-container">
                        <div className="secondary-video">
                          <video
                            id="video2"
                            ref={localVideoRef}
                            muted={true}
                            playsInline
                            autoPlay
                            width='100%'
                            height='100%'
                            className="local_video self-video"
                            sx={{ borderRadius: "inherit", boxSizing: 'border-box' }}
                          />
                        </div>
                      </Box>
                      {/* secondary view - shufflable */}
                      <Box sx={{ aspectRatio: '16/9', width: '200px', borderRadius: "10px", position: 'relative', border: 'solid 1px rgba(255,255,255,0.16)', margin: '7px 0 0 7px', boxSizing: 'border-box', display: ((isMainView && isRokidAstJoin) || (!isMainView && isWebAstJoin)) ? "block" : "none" }} className="secondary-video-container">
                        <Box backgroundColor="rgba(0,0,0,0.75)" sx={{ position: 'absolute', top: 0, left: 0, right: 0 }} py={0.4} px={1}>
                          <Typography variant="subtitle2" color={'white'} display={'flex'}>
                            {!isMainView && (
                              t('lbl_patientViewBtn')
                            )}
                            {isMainView && (
                              t('lbl_smartGlassViewBtn')
                            )}
                            {(isMuteRokid && !showMuteIcon) || (isWebAsstMuted && showMuteIcon) ?
                              <MicOffIcon color="error" sx={{ paddingLeft: 1 }} /> : null
                            }
                          </Typography>
                        </Box>
                        <div id="container2" className="secondary-video">
                          <video
                            id="video3"
                            ref={remoteVideoRef2}
                            muted={true}
                            playsInline
                            autoPlay
                            className="local_video "
                            onPlay={onPlayHandler}
                            sx={{ borderRadius: "inherit", boxSizing: 'border-box' }}
                          />
                        </div>
                        {/* shuffle view icon */}
                        <Box sx={{ position: "absolute", bottom: '6px', right: '5px' }}>
                          <Tooltip
                            title={isMainView ? t('lbl_switchSmartGlass') : t("lbl_switchMainGlass")}
                            placement="top-start" arrow>
                            <IconButton
                              aria-label="shuffle-video-icon"
                              size="small"
                              color={"inherit"}
                              className="custom-default shuffle-video"
                              disableRipple={true}
                              onClick={switchVideoContainers}
                            >
                              <span className="cad-shuffle-video rotate-temp"></span>
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                    {/* </div> */}
                  </Grid>) : null}
              </Grid>
            </Box>
            {/* bottom grid here */}
            <Grid container alignItems="center" height={{ xs: '168px', sm: '84px', md: '42px' }}>
              <Grid item md={4.15} lg={4} xs={12} sm={6} order={{ xs: 2, md: 1 }}>
                <Stack direction="row" spacing={0.5}>
                  <CadisButton size="small" sx={{ pointerEvents: ((showAnnotateBtn && isRokidAstJoin) || (isNormalCall)) ? 'auto' : 'none', }} color={((showAnnotateBtn && isRokidAstJoin) || (isNormalCall)) ? 'info' : 'inherit'} variant="contained" onClick={() => setAnnotate(!annotate)} >
                    {annotate ? (
                      t("lbl_annotateOff")
                    ) : (
                      t("lbl_annotate")
                    )}
                  </CadisButton>

                  {annotate && (
                    <Stack direction="row" spacing={0.5}>
                      <IconButton aria-label="circle" size="small"
                        className={`${isButtonSelected === "circle" ? "active-annotation-child" : "custom-default-annotation"}`}
                        disableRipple={true}
                        onClick={() => handledrawCircle('circle')} >
                        <CircleOutlinedIcon />
                      </IconButton>

                      <IconButton aria-label="sqaure" size="small" className={`${isButtonSelected === "rect" ? "active-annotation-child" : "custom-default-annotation"}`}
                        color={isButtonSelected === 'rect' ? 'success' : 'default'} disableRipple={true}
                        onClick={() => {
                          sessionStorage.setItem("userTyping", "false")
                          drawRectangle('rect')
                        }} >
                        <CropSquareRoundedIcon />
                      </IconButton>

                      <IconButton aria-label="pencil" size="small" className={`${isButtonSelected === "FreeHand" ? "active-annotation-child" : "custom-default-annotation"}`}
                        color={isButtonSelected === 'FreeHand' ? 'success' : 'default'} disableRipple={true}
                        onClick={() => {
                          sessionStorage.setItem("userTyping", "false")
                          handleDrawFreeHand('freehand')
                        }} >
                        <span className="cad-pen"></span>
                      </IconButton>

                      <IconButton aria-label="takeNoteIcon" size="small" className={`${selectedIcon === "text" ? "active-annotation-child" : "custom-default-annotation"}`}
                        color="default"
                        disableRipple={true}
                        onClick={() => {
                          setTakeNotes(!takeNotes);
                          setIsButtonSelected("")
                          setIsSend(false);
                          sessionStorage.setItem("userTyping", "true")
                          if (selectedIcon !== 'text' && !takeNotes) setSelectedIcon('text'); else setSelectedIcon('');
                        }} >
                        <TitleSharpIcon />
                      </IconButton>

                      <IconButton aria-label="clearAnotationIcon" size="small" color="error" disableRipple={true}
                        onClick={handleClearAnnotation} >
                        <CancelOutlinedIcon />
                      </IconButton>

                    </Stack>
                  )}
                </Stack>
              </Grid>
              <Grid item md={5.2} lg={4} xs={12} order={{ xs: 1, md: 2 }}>
                <CadisButton size="small" variant="contained" color={!isConferenceCall ? 'success' :
                  (((isMainView && isWebAstJoin) || (!isMainView && isRokidAstJoin)) ? 'success' : "inherit")}
                  sx={{ pointerEvents: !isConferenceCall ? 'auto' : ((isMainView && isWebAstJoin) || (!isMainView && isRokidAstJoin)) ? 'auto' : 'none' }} startIcon={<CenterFocusWeakIcon />} onClick={snapshotHandler} >
                  Take a Picture
                </CadisButton>
                <Box display="inline-block" paddingX={1}>
                  {isConferenceCall ? <ToggleComponent sfutest={sfutest} isVideo={true} socketRef={socketRef} /> : ""} {/* Video toggle */}
                  <ToggleComponent sfutest={sfutest} isVideo={false} socketRef={socketRef} /> {/* Audio toggle */}
                </Box>
                <Box sx={{ position: 'relative', display: 'inline-block' }}>
                  <CadisButton
                    size="small"
                    variant="contained"
                    color={!isConferenceCall ? (recording ? 'error' : 'success') :
                      (((isMainView && isWebAstJoin) || (!isMainView && isRokidAstJoin)) ?
                        (recording ? 'error' : 'success') : "inherit")}
                    sx={{
                      pointerEvents: !isConferenceCall ? 'auto' :
                        ((isMainView && isWebAstJoin) || (!isMainView && isRokidAstJoin)) ? 'auto' : 'none'
                    }}
                    startIcon={
                      recording ? (
                        <StopIcon />
                      ) : (
                        <FiberManualRecordIcon
                          size={"small"}
                          sx={{ color: !isConferenceCall ? 'error.main' : ((isMainView && isWebAstJoin) || (!isMainView && isRokidAstJoin)) ? 'error.main' : 'grey.dark' }}
                        />
                      )
                    }
                    onClick={recording ? handleStopRecording : handleStartRecording}
                  >
                    {recording ? (
                      t("lbl_stopRecording")
                    ) : (
                      t("lbl_recordSession")
                    )}
                  </CadisButton>
                  {recording && (
                    <Box display={"flex"} alignItems={"center"} position="absolute" left={'calc(100% + 5px)'} top={'3px'} className="recording-started-border" paddingX={.6} paddingY={0} lineHeight="0">
                      <Box component={"span"} sx={{ color: "error.main" }}>
                        <FiberManualRecordIcon />
                      </Box>
                      <Box component={"span"}>
                        <Typography color="#ffffff">{t('lbl_rec')}</Typography>
                      </Box>
                    </Box>
                  )}

                </Box>
              </Grid>
              <Grid item md={2.65} lg={4} xs={12} sm={6} order={{ xs: 3, md: 3 }} display="flex" justifyContent="space-between" alignContent="center">
                <Box display="inline-block">
                  <div className="zoom_icons" >
                    <ZoomOutOutlinedIcon fontSize={"large"} sx={{ cursor: 'pointer' }} onClick={zoomOut} />
                    <Box sx={{ borderRadius: 2, border: '1px solid #fff', paddingY: '1px', paddingX: '2px', marginX: '6px', minWidth: '36px', textAlign: "center" }}>
                      <Typography fontWeight='bold'>{zoomLevel / 100 * 100 - 100}%</Typography>
                    </Box>
                    <ZoomInOutlinedIcon fontSize={"large"} sx={{ cursor: 'pointer' }} onClick={zoomIn} />
                  </div>
                </Box>
                <CadisButton size="small" variant="contained" color="error" onClick={handleEndCall} sx={{ float: 'right', }} >
                  {t('lbl_endCallBtn')}
                </CadisButton>
              </Grid>
            </Grid>
          </>
          <canvas id="snapshot_canvas" width={920} height={520} style={{ display: "none" }}>

          </canvas>
          <canvas id="recording_canvas" width={920} height={520} style={{ display: "none" }}>

          </canvas>
        </Box>
      </Box >
    </>
  );
};

export default CallScreen;